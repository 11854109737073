import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
  Autocomplete,
  Fab,
  Chip,
  Tooltip,
  Snackbar,
  Alert,
  Dialog as ConfirmDialog,
  DialogTitle as ConfirmDialogTitle,
  DialogActions as ConfirmDialogActions
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import { DataGrid } from '@mui/x-data-grid';
import axios from 'axios';
import { API_BASE_URL } from '../../../constants/constants';
import { useNavigate } from 'react-router-dom';

const DeletePincode = () => {
  const [pincodes, setPincodes] = useState([]);
  const [open, setOpen] = useState(false);
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false); // State for confirmation dialog
  const [districts, setDistricts] = useState([]);
  const [taluks, setTaluks] = useState([]);
  const [selectedDistrict, setSelectedDistrict] = useState(null);
  const [selectedTaluk, setSelectedTaluk] = useState(null);
  const [selectedPincodes, setSelectedPincodes] = useState([]);
  const [removedPincodes, setRemovedPincodes] = useState([]);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarSeverity, setSnackbarSeverity] = useState('error');
  const [message, setMessage] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    fetchDistricts();
    fetchPincodes();
  }, []);

  const fetchPincodes = async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/pincodes/details`);
      if (Array.isArray(response.data)) {
        setPincodes(response.data);
      } else {
        setPincodes([]);
      }
    } catch (error) {
      console.error('Error fetching pincodes:', error);
      setPincodes([]);
    }
  };

  const fetchDistricts = async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/districts`);
      setDistricts(response.data);
    } catch (error) {
      console.error('Error fetching districts:', error);
    }
  };

  const fetchTaluks = async (districtId) => {
    try {
      const response = await axios.get(`${API_BASE_URL}/taluks/district/${districtId}`);
      setTaluks(response.data);
    } catch (error) {
      console.error('Error fetching taluks:', error);
    }
  };

  const fetchTalukPincodes = async (talukId) => {
    try {
      const response = await axios.get(`${API_BASE_URL}/pincodes/taluk/${talukId}`);
      if (response.data.pincodes.length > 0) {
        setSelectedPincodes(response.data.pincodes);
      } else {
        setMessage('No pincodes found for this taluk.');
        setSnackbarSeverity('info');
        setSnackbarOpen(true);
      }
    } catch (error) {
      console.error('Error fetching taluk pincodes:', error);
    }
  };

  const handleDistrictChange = (event, value) => {
    setSelectedDistrict(value);
    setSelectedTaluk(null);
    setSelectedPincodes([]);
    setRemovedPincodes([]);
    if (value) {
      fetchTaluks(value._id);
    }
  };

  const handleTalukChange = (event, value) => {
    setSelectedTaluk(value);
    setSelectedPincodes([]);
    setRemovedPincodes([]);
    if (value) {
      fetchTalukPincodes(value._id);
    }
  };

  const handlePincodeDelete = (pincode) => {
    setRemovedPincodes([...removedPincodes, pincode]);
    setSelectedPincodes(selectedPincodes.filter(pc => pc !== pincode));
  };

  const handleOpenDialog = () => {
    setOpen(true);
  };

  const handleCloseDialog = () => {
    if (removedPincodes.length > 0) {
      setConfirmDialogOpen(true); // Open confirmation dialog if there are unsaved changes
    } else {
      clearDialogData();
    }
  };

  const clearDialogData = () => {
    setOpen(false);
    setSelectedDistrict(null);
    setSelectedTaluk(null);
    setSelectedPincodes([]);
    setRemovedPincodes([]);
  };

  const handleSubmit = async () => {
    if (selectedDistrict && selectedTaluk && removedPincodes.length > 0) {
      try {
        await axios.delete(`${API_BASE_URL}/pincodes`, {
          data: {
            districtId: selectedDistrict._id,
            talukId: selectedTaluk._id,
            pincodes: removedPincodes,
          },
        });

        setSnackbarSeverity('success');
        setMessage('Pincode(s) deleted successfully.');
        setSnackbarOpen(true);
        clearDialogData();
        fetchPincodes(); // Refresh the pincode list
      } catch (error) {
        console.error('Error deleting pincodes:', error);
        setSnackbarSeverity('error');
        setMessage(error.response.data.message || 'Error deleting pincodes');
        setSnackbarOpen(true);
      }
    } else {
      setSnackbarSeverity('error');
      setMessage('Please select pincodes to delete.');
      setSnackbarOpen(true);
    }
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const handleConfirmDialogClose = () => {
    setConfirmDialogOpen(false);
  };

  const handleConfirmDialogProceed = () => {
    setConfirmDialogOpen(false);
    clearDialogData();
  };

  const columns = [
    { field: 'districtName', headerName: 'District', width: 190 },
    { field: 'talukName', headerName: 'Taluk', width: 150 },
    { field: 'pincodes', headerName: 'Pincodes', width: 300, renderCell: (params) => params.value.join(', ') },
  ];

  const rows = pincodes.map((pincode, index) => ({
    id: index + 1,
    districtName: pincode.districtName,
    talukName: pincode.talukName,
    pincodes: pincode.pincodes,
  }));

  return (
    <Box sx={{ height: 400, width: '100%', position: 'relative' }}>
      <Typography variant="h4" gutterBottom>
        Pincode Management
      </Typography>
      <DataGrid rows={rows} columns={columns} autoHeight  getRowHeight={() => 'auto'}/>
      <Tooltip title="Delete Pincodes">
        <Fab
          color="secondary"
          aria-label="delete"
          sx={{ position: 'absolute', top: -7, right: 16 }}
          onClick={handleOpenDialog}
        >
          <DeleteIcon />
        </Fab>
      </Tooltip>
      <Dialog open={open} onClose={handleCloseDialog} fullWidth maxWidth="sm">
        <DialogTitle>Delete Pincodes</DialogTitle>
        <DialogContent>
          <Autocomplete
            options={districts}
            getOptionLabel={(option) => option.name}
            value={selectedDistrict}
            onChange={handleDistrictChange}
            renderInput={(params) => <TextField {...params} label="Select District" variant="outlined" />}
            sx={{ mb: 2 }}
          />
          <Autocomplete
            options={taluks}
            getOptionLabel={(option) => option.name}
            value={selectedTaluk}
            onChange={handleTalukChange}
            renderInput={(params) => <TextField {...params} label="Select Taluk" variant="outlined" />}
            sx={{ mb: 2 }}
          />
          {selectedPincodes.length > 0 ? (
            <Box sx={{ mb: 2 }}>
              {selectedPincodes.map((pincode, index) => (
                <Chip
                  key={index}
                  label={pincode}
                  onDelete={() => handlePincodeDelete(pincode)}
                  sx={{ mr: 1, mb: 1 }}
                />
              ))}
            </Box>
          ) : (
            <Typography>No pincodes found for this taluk.</Typography>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => navigate('/adminControl/pincodes/create')}
            color="primary"
            variant="contained"
            sx={{ mr: 'auto' }}
          >
            Create Pincode
          </Button>
          <Button onClick={handleCloseDialog} color="secondary">
            Cancel
          </Button>
          <Button onClick={handleSubmit} color="primary" variant="contained">
            Submit
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={5000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      >
        <Alert onClose={handleSnackbarClose} severity={snackbarSeverity} sx={{ whiteSpace: 'pre-line' }}>
          {message}
        </Alert>
      </Snackbar>

      {/* Confirmation Dialog for unsaved changes */}
      <ConfirmDialog
        open={confirmDialogOpen}
        onClose={handleConfirmDialogClose}
      >
        <ConfirmDialogTitle>Unsaved Changes</ConfirmDialogTitle>
        <DialogContent>
          <Typography>You have unsaved changes. Do you really want to close?</Typography>
        </DialogContent>
        <ConfirmDialogActions>
          <Button onClick={handleConfirmDialogClose} color="secondary">
            Cancel
          </Button>
          <Button onClick={handleConfirmDialogProceed} color="primary" variant="contained">
            Proceed
          </Button>
        </ConfirmDialogActions>
      </ConfirmDialog>
    </Box>
  );
};

export default DeletePincode;
