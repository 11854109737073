import React, { useState, useContext } from 'react';
import { Box, AppBar, CssBaseline, Drawer, Toolbar, Typography, IconButton, Menu, MenuItem } from '@mui/material';
import Sidebar from './Sidebar';
import Router from './Router';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import PersonIcon from '@mui/icons-material/Person';
import LogoutIcon from '@mui/icons-material/Logout';
import { useNavigate } from 'react-router-dom';
import AuthContext from '../../context/AuthContext';

const drawerWidth = 270; // Adjust the width as needed
const AppbarHeight = 64;

const Frame = () => {
    const navigate = useNavigate();
    const user = JSON.parse(localStorage.getItem('user'));
    const userFirstName = user?.userFirstName || 'User';
    const userLastName = user?.userLastName || '';

    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const { logout } = useContext(AuthContext);
    const handleMenuClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const handleProfileClick = () => {
        navigate('/profile');
        handleMenuClose();
    };

    const handleLogoutClick = () => {
        localStorage.clear();
        navigate('/login');
        handleMenuClose();
    };

    return (
        <Box sx={{ display: 'flex', overflow: 'none', height: '98vh' }}>
            <CssBaseline />
            <AppBar
                position="fixed"
                sx={{ width: `calc(100% - ${drawerWidth + 4}px)`, ml: `${drawerWidth + 4}px`, height: AppbarHeight }}
            >
                <Toolbar>
                    <Typography variant="h5" noWrap component="div" sx={{ flexGrow: 1, color: 'white' }}>
                        Welcome, {userFirstName} {userLastName}
                    </Typography>
                    <IconButton
                        edge="end"
                        color="inherit"
                        aria-label="profile"
                        aria-controls="profile-menu"
                        aria-haspopup="true"
                        sx={{color: 'white'}}
                        onClick={handleMenuClick}
                    >
                        <AccountCircleIcon />
                    </IconButton>
                    <Menu
                     
                        id="profile-menu"
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleMenuClose}
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                    >
                        <MenuItem onClick={handleProfileClick}>
                            <PersonIcon sx={{ mr: 2 }} />
                            Profile
                        </MenuItem>
                        <MenuItem onClick={logout}>
                            <LogoutIcon sx={{ mr: 2 }} />
                            Logout
                        </MenuItem>
                    </Menu>
                </Toolbar>
            </AppBar>
            <Drawer
                variant="permanent"
                sx={{
                    width: drawerWidth,
                    flexShrink: 0,
                    [`& .MuiDrawer-paper`]: { width: drawerWidth, boxSizing: 'border-box' },
                }}
            >
                <Toolbar sx={{ backgroundColor: 'primary.main', borderBlockColor: 'gray' }} elevation={5}>
                    <Typography  variant="h4" noWrap component="div" sx={{fontFamily:"Merriweather, serif", fontWeight:400,float:'center',ml:4,mr:4}}>
                        My Pay
                    </Typography>
                </Toolbar>
                <Sidebar />
            </Drawer>
            <Box
                component="main"
                sx={{ flexGrow: 1, bgcolor: 'background.default', p: 3, ml: `4px`, height: '91vh', mt: 9, overflow: 'auto', mr: 1 }}
            >
                <Toolbar />
                <Router />
            </Box>
        </Box>
    );
};

export default Frame;
