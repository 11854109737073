import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  TextField,
  Typography,
  Snackbar,
  Alert,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
  CircularProgress,
  Card,
  CardContent,
} from '@mui/material';
import axios from 'axios';
import { API_BASE_URL } from '../../constants/constants';
import { jwtDecode } from 'jwt-decode';

const AddShop = () => {
  const [shopName, setShopName] = useState('');
  const [shopAddress, setShopAddress] = useState('');
  const [shopType, setShopType] = useState('');
  const [shopPincode, setShopPincode] = useState('');
  const [shopLicanceNumber, setShopLicanceNumber] = useState('');
  const [shopGstNumber, setShopGstNumber] = useState('');
  const [shopEmail, setShopEmail] = useState('');
  const [promoterName, setPromoterName] = useState('');
  const [promoterContact, setPromoterContact] = useState('');
  const [promoterAdhaar, setPromoterAdhaar] = useState('');
  const [promoterPan, setPromoterPan] = useState('');
  const [promoterWhatsApp, setPromoterWhatsApp] = useState('');
  const [promoterEmail, setPromoterEmail] = useState('');
  const [shopAccountNumber, setShopAccountNumber] = useState('');
  const [shopBank, setShopBank] = useState('');
  const [shopBranch, setShopBranch] = useState('');
  const [shopIFSC, setShopIFSC] = useState('');
  const [shopLicance, setShopLicance] = useState('');
  const [shopGstCertificate, setShopGstCertificate] = useState('');
  const [adhaar, setAdhaar] = useState('');
  const [pan, setPan] = useState('');
  const [bankProof, setBankProof] = useState('');
  const [shopPhoto, setShopPhoto] = useState('');
  const [shopLicanceName, setShopLicanceName] = useState('');
  const [shopGstCertificateName, setShopGstCertificateName] = useState('');
  const [adhaarName, setAdhaarName] = useState('');
  const [panName, setPanName] = useState('');
  const [bankProofName, setBankProofName] = useState('');
  const [shopPhotoName, setShopPhotoName] = useState('');
  const [loading, setLoading] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');
  const [userId, setUserId] = useState(null);
  const [errors, setErrors] = useState({});

  const shopCategories = [
    "Grocery",
    "Books",
    "Food",
    "Gold & Jewelry",
    "Electronics",
    "Clothing",
    "Furniture",
    "Pharmacy",
    "Toys",
    "Sports & Fitness",
    "Beauty & Personal Care",
    "Home Decor",
    "Stationery",
    "Pet Supplies",
    "Auto Parts",
    "Hardware",
    "Bakery",
    "Florist",
    "Liquor Store",
    "Gift Shop",
    "Other"
  ];

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      const decodedToken = jwtDecode(token);
      setUserId(decodedToken.userId);
    }
  }, []);

  const handleFileUpload = (e, setFile, setFileName) => {
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.onloadend = () => {
      // The result attribute contains the data as a base64 encoded string
      setFile(reader.result);
      setFileName(file.name);
    };
    reader.readAsDataURL(file);
  };


  const validateFields = () => {
    const newErrors = {};
    if (!shopName) newErrors.shopName = 'Shop Name is required';
    if (!shopAddress) newErrors.shopAddress = 'Shop Address is required';
    if (!shopType) newErrors.shopType = 'Shop Type is required';
    if (!shopPincode) newErrors.shopPincode = 'Shop Pincode is required';
    if (!promoterName) newErrors.promoterName = 'Promoter Name is required';
    if (!promoterContact) newErrors.promoterContact = 'Promoter Contact is required';
    if (!shopAccountNumber) newErrors.shopAccountNumber = 'Shop Account Number is required';
    if (!shopBank) newErrors.shopBank = 'Shop Bank is required';
    if (!shopBranch) newErrors.shopBranch = 'Shop Branch is required';
    if (!shopIFSC) newErrors.shopIFSC = 'Shop IFSC is required';
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async () => {
    if (!validateFields()) {
      setSnackbarMessage('Please fill all the required fields');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
      return;
    }

    const payload = {
      shopName,
      shopAddress,
      shopType,
      shopPincode,
      shopLicanceNumber,
      shopGstNumber,
      shopEmail,
      promoterName,
      promoterContact,
      promoterAdhaar,
      promoterPan,
      promoterWhatsApp,
      promoterEmail,
      shopAccountNumber,
      shopBank,
      shopBranch,
      shopIFSC,
      shopLicance,
      shopGstCertificate,
      adhaar,
      pan,
      bankProof,
      shopPhoto,
    };

    setLoading(true);
    try {
      const response = await axios.post(`${API_BASE_URL}/shops/${userId}`, payload, {
        headers: {
          'Content-Type': 'application/json',
        },
      });
      setSnackbarMessage('Shop added successfully');
      setSnackbarSeverity('success');
    } catch (error) {
      setSnackbarMessage('Error adding shop: ' + (error.response?.data?.message || error.message));
      setSnackbarSeverity('error');
    } finally {
      setLoading(false);
      setSnackbarOpen(true);
    }
  };

  return (
    <Box sx={{ width: '60%', mx: 'auto' }}>
      <Typography variant="h4" sx={{ mb: 2 }}>Add Shop</Typography>
      <Card sx={{ height: '95vh', display: 'flex', flexDirection: 'column' }}>
        <CardContent sx={{ flexGrow: 1, overflow: 'auto', position: 'relative' }}>
          {loading ? (
            <CircularProgress />
          ) : (
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: 2,
              }}
            >
              <TextField
                label="Shop Name"
                value={shopName}
                onChange={(e) => setShopName(e.target.value)}
                fullWidth
                error={!!errors.shopName}
                helperText={errors.shopName}
              />
              <TextField
                label="Shop Address"
                value={shopAddress}
                onChange={(e) => setShopAddress(e.target.value)}
                fullWidth
                error={!!errors.shopAddress}
                helperText={errors.shopAddress}
              />
              <FormControl fullWidth error={!!errors.shopType}>
                <InputLabel id="shopType-label">Shop Type</InputLabel>
                <Select
                  labelId="shopType-label"
                  value={shopType}
                  onChange={(e) => setShopType(e.target.value)}
                >
                  {shopCategories.map((category) => (
                    <MenuItem key={category} value={category}>{category}</MenuItem>
                  ))}
                </Select>
                {errors.shopType && <Typography color="error">{errors.shopType}</Typography>}
              </FormControl>
              <TextField
                label="Shop Pincode"
                value={shopPincode}
                onChange={(e) => setShopPincode(e.target.value)}
                fullWidth
                error={!!errors.shopPincode}
                helperText={errors.shopPincode}
              />
              <TextField
                label="Shop License Number"
                value={shopLicanceNumber}
                onChange={(e) => setShopLicanceNumber(e.target.value)}
                fullWidth
              />
              <TextField
                label="Shop GST Number"
                value={shopGstNumber}
                onChange={(e) => setShopGstNumber(e.target.value)}
                fullWidth
              />
              <TextField
                label="Shop Email"
                value={shopEmail}
                onChange={(e) => setShopEmail(e.target.value)}
                fullWidth
              />
              <TextField
                label="Promoter Name"
                value={promoterName}
                onChange={(e) => setPromoterName(e.target.value)}
                fullWidth
                error={!!errors.promoterName}
                helperText={errors.promoterName}
              />
              <TextField
                label="Promoter Contact"
                value={promoterContact}
                onChange={(e) => setPromoterContact(e.target.value)}
                fullWidth
                error={!!errors.promoterContact}
                helperText={errors.promoterContact}
              />
              <TextField
                label="Promoter Aadhar"
                value={promoterAdhaar}
                onChange={(e) => setPromoterAdhaar(e.target.value)}
                fullWidth
              />
              <TextField
                label="Promoter PAN"
                value={promoterPan}
                onChange={(e) => setPromoterPan(e.target.value)}
                fullWidth
              />
              <TextField
                label="Promoter WhatsApp"
                value={promoterWhatsApp}
                onChange={(e) => setPromoterWhatsApp(e.target.value)}
                fullWidth
              />
              <TextField
                label="Promoter Email"
                value={promoterEmail}
                onChange={(e) => setPromoterEmail(e.target.value)}
                fullWidth
              />
              <TextField
                label="Shop Account Number"
                value={shopAccountNumber}
                onChange={(e) => setShopAccountNumber(e.target.value)}
                fullWidth
                error={!!errors.shopAccountNumber}
                helperText={errors.shopAccountNumber}
              />
              <TextField
                label="Shop Bank"
                value={shopBank}
                onChange={(e) => setShopBank(e.target.value)}
                fullWidth
                error={!!errors.shopBank}
                helperText={errors.shopBank}
              />
              <TextField
                label="Shop Branch"
                value={shopBranch}
                onChange={(e) => setShopBranch(e.target.value)}
                fullWidth
                error={!!errors.shopBranch}
                helperText={errors.shopBranch}
              />
              <TextField
                label="Shop IFSC"
                value={shopIFSC}
                onChange={(e) => setShopIFSC(e.target.value)}
                fullWidth
                error={!!errors.shopIFSC}
                helperText={errors.shopIFSC}
              />

              {/* File Uploads */}
              <Box sx={{ display: 'grid', gridTemplateColumns: 'repeat(2, 1fr)', gap: 2 }}>
                <input
                  accept="image/*"
                  style={{ display: 'none' }}
                  id="shop-photo"
                  type="file"
                  onChange={(e) => handleFileUpload(e, setShopPhoto, setShopPhotoName)}
                />
                <label htmlFor="shop-photo">
                  <Button variant="contained" component="span" fullWidth>
                    Upload Shop Photo
                  </Button>
                </label>
                {shopPhotoName && <Typography>{shopPhotoName}</Typography>}

                <input
                  accept="application/pdf,image/*"
                  style={{ display: 'none' }}
                  id="shop-licance"
                  type="file"
                  onChange={(e) => handleFileUpload(e, setShopLicance, setShopLicanceName)}
                />
                <label htmlFor="shop-licance">
                  <Button variant="contained" component="span" fullWidth>
                    Upload Shop License
                  </Button>
                </label>
                {shopLicanceName && <Typography>{shopLicanceName}</Typography>}

                <input
                  accept="application/pdf,image/*"
                  style={{ display: 'none' }}
                  id="shop-gst-certificate"
                  type="file"
                  onChange={(e) => handleFileUpload(e, setShopGstCertificate, setShopGstCertificateName)}
                />
                <label htmlFor="shop-gst-certificate">
                  <Button variant="contained" component="span" fullWidth>
                    Upload GST Certificate
                  </Button>
                </label>
                {shopGstCertificateName && <Typography>{shopGstCertificateName}</Typography>}

                <input
                  accept="application/pdf,image/*"
                  style={{ display: 'none' }}
                  id="adhaar"
                  type="file"
                  onChange={(e) => handleFileUpload(e, setAdhaar, setAdhaarName)}
                />
                <label htmlFor="adhaar">
                  <Button variant="contained" component="span" fullWidth>
                    Upload Aadhar
                  </Button>
                </label>
                {adhaarName && <Typography>{adhaarName}</Typography>}

                <input
                  accept="application/pdf,image/*"
                  style={{ display: 'none' }}
                  id="pan"
                  type="file"
                  onChange={(e) => handleFileUpload(e, setPan, setPanName)}
                />
                <label htmlFor="pan">
                  <Button variant="contained" component="span" fullWidth>
                    Upload PAN
                  </Button>
                </label>
                {panName && <Typography>{panName}</Typography>}

                <input
                  accept="application/pdf,image/*"
                  style={{ display: 'none' }}
                  id="bank-proof"
                  type="file"
                  onChange={(e) => handleFileUpload(e, setBankProof, setBankProofName)}
                />
                <label htmlFor="bank-proof">
                  <Button variant="contained" component="span" fullWidth>
                    Upload Bank Proof
                  </Button>
                </label>
                {bankProofName && <Typography>{bankProofName}</Typography>}
              </Box>
            </Box>
          )}
        </CardContent>
        <Box sx={{ p: 2 }}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleSubmit}
            fullWidth
          >
            Submit
          </Button>
        </Box>
      </Card>

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={() => setSnackbarOpen(false)}
      >
        <Alert
          onClose={() => setSnackbarOpen(false)}
          severity={snackbarSeverity}
          sx={{ width: '100%' }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default AddShop;