import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
  Fab,
  Tooltip,
  Snackbar,
  Alert,
  Autocomplete,
  Chip,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { DataGrid } from '@mui/x-data-grid';
import axios from 'axios';
import { API_BASE_URL } from '../../../constants/constants';
import { jwtDecode } from 'jwt-decode';

const AssignPincodes = () => {
  const [users, setUsers] = useState([]);
  const [open, setOpen] = useState(false);
  const [addPincodeOpen, setAddPincodeOpen] = useState(false); // Dialog for adding pincode to DB
  const [selectedUser, setSelectedUser] = useState(null);
  const [availablePincodes, setAvailablePincodes] = useState([]);
  const [selectedPincodes, setSelectedPincodes] = useState([]);
  const [districts, setDistricts] = useState([]);
  const [selectedDistrict, setSelectedDistrict] = useState(null);
  const [taluks, setTaluks] = useState([]);
  const [selectedTaluk, setSelectedTaluk] = useState(null);
  const [newPincodes, setNewPincodes] = useState([]); // For new pincodes to be added to DB
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');
  const [userLevel, setUserLevel] = useState(null);
  const [userId, setUserId] = useState(null);
  const [hasPrivilege, setHasPrivilege] = useState(false);

  const PRIVILEGE_MODULE = 1314;

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      const decodedToken = jwtDecode(token);
      setUserLevel(decodedToken.level);
      setUserId(decodedToken.userId);
      checkPrivilege(decodedToken);
      fetchUsers(decodedToken.userId);
    }
  }, []);

  const checkPrivilege = async (decodedToken) => {
    if (decodedToken.level <= 2) {
      setHasPrivilege(true);
    } else {
      try {
        const response = await axios.get(`${API_BASE_URL}/privileges/modules/${decodedToken.userId}`);
        setHasPrivilege(response.data.modules.includes(PRIVILEGE_MODULE));
      } catch (error) {
        console.error('Error checking privilege:', error);
        setHasPrivilege(false);
      }
    }
  };

  const fetchUsers = async (userId) => {
    try {
      const response = await axios.get(`${API_BASE_URL}/users-with-pincodes/${userId}`);
      setUsers(response.data);
    } catch (error) {
      console.error('Error fetching users:', error);
      showSnackbar('Error fetching users', 'error');
    }
  };

  const fetchAvailablePincodes = async (userId) => {
    try {
      if (userLevel <= 2) {
        const response = await axios.get(`${API_BASE_URL}/available-pincodes/${userId}`);
        setAvailablePincodes(response.data);
      } else {
        // Fetch pincodes assigned to the user in their privileges
        const response = await axios.get(`${API_BASE_URL}/privileges/${userId}`);
        const pincodes = response.data.pincodes || [];
        if (pincodes.length > 0) {
          setAvailablePincodes(pincodes);
        } else {
          showSnackbar('No pincodes assigned to you', 'info');
          setAvailablePincodes([]);
        }
      }
    } catch (error) {
      console.error('Error fetching available pincodes:', error);
      showSnackbar('Error fetching available pincodes', 'error');
    }
  };

  const fetchDistricts = async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/districts`);
      setDistricts(response.data);
    } catch (error) {
      console.error('Error fetching districts:', error);
      showSnackbar('Error fetching districts', 'error');
    }
  };

  const fetchTaluks = async (districtId) => {
    try {
      const response = await axios.get(`${API_BASE_URL}/taluks/district/${districtId}`);
      setTaluks(response.data);
    } catch (error) {
      console.error('Error fetching taluks:', error);
      showSnackbar('Error fetching taluks', 'error');
    }
  };

  const fetchPincodes = async (talukId) => {
    setAvailablePincodes([]);
    try {
      const response = await axios.get(`${API_BASE_URL}/pincodes/taluk/${talukId}`);
      setAvailablePincodes(response.data.pincodes || []);
    } catch (error) {
      console.error('Error fetching pincodes:', error);
      showSnackbar('Error fetching pincodes', 'error');
    }
  };

  const handleOpenDialog = () => {
    if (!hasPrivilege) {
      showSnackbar('You do not have permission to assign pincodes', 'error');
      return;
    }
    setOpen(true);
    if (userLevel <= 2) {
      fetchDistricts();
    } else {
      fetchAvailablePincodes(userId);
    }
  };

  const handleCloseDialog = () => {
    setOpen(false);
    resetDialogState();
  };

  const resetDialogState = () => {
    setSelectedUser(null);
    setSelectedDistrict(null);
    setSelectedTaluk(null);
    setSelectedPincodes([]);
  };

  const handleUserChange = (event, value) => {
    setSelectedUser(value);
    setSelectedPincodes(value ? value.pincodes : []);
  };

  const handleDistrictChange = (event, value) => {
    setSelectedDistrict(value);
    setSelectedTaluk(null);
    if (value) {
      fetchTaluks(value._id);
    }
  };

  const handleTalukChange = (event, value) => {
    setSelectedTaluk(value);
    if (value) {
      fetchPincodes(value._id);
    } else {
      setAvailablePincodes([]);
    }
  };

  const handlePincodeChange = (event, newValue) => {
    setSelectedPincodes(newValue);
  };

  const handleAddNewPincodeChange = (event) => {
    if (event.key === 'Enter' && event.target.value.length === 6) {
      const value = event.target.value;
      if (/^\d{6}$/.test(value)) {
        setNewPincodes((prev) => [...prev, value]);
        event.target.value = '';
      } else {
        showSnackbar('Pincode must be 6 digits', 'error');
      }
    }
  };

  const handleDeleteNewPincode = (pincodeToDelete) => {
    setNewPincodes(newPincodes.filter((pincode) => pincode !== pincodeToDelete));
  };

  const handleSubmit = async () => {
    if (!selectedUser) {
        showSnackbar('Please select a user', 'error');
        return;
    }

    try {
        // Check if the user already has privileges
        const { data } = await axios.get(`${API_BASE_URL}/privileges/${selectedUser.id}`);

        const currentPincodes = data?.pincodes || [];
        const addPincodes = selectedPincodes.filter((p) => !currentPincodes.includes(p));
        const removePincodes = currentPincodes.filter((p) => !selectedPincodes.includes(p));

        if (data?.userId) {
            // If privileges exist, update them
            await axios.put(`${API_BASE_URL}/privileges/${selectedUser.id}`, {
                addPincodes,
                removePincodes,
            });

            showSnackbar('Pincodes updated successfully', 'success');
        } else {
            // If no privileges exist, create new ones
            await axios.post(`${API_BASE_URL}/privileges`, {
                userId: selectedUser.id,
                pincodes: selectedPincodes,
                modules: [], // Assuming no modules are being added or passed here
            });

            showSnackbar('Privileges created successfully', 'success');
        }

        handleCloseDialog();
        fetchUsers(userId);
    } catch (error) {
        console.error('Error managing privileges:', error);
        showSnackbar('Error managing privileges', 'error');
    }
};

  const handleAddPincodeToDB = async () => {
    if (!selectedDistrict || !selectedTaluk || newPincodes.length === 0) {
      showSnackbar('Please select district, taluk, and add pincodes', 'error');
      return;
    }

    try {
      await axios.post(`${API_BASE_URL}/add-pincode-to-db`, {
        districtId: selectedDistrict._id,
        talukId: selectedTaluk._id,
        pincodes: newPincodes,
      });

      showSnackbar('Pincodes added to database successfully', 'success');
      fetchPincodes(selectedTaluk._id); // Refresh the available pincodes after adding
      setAddPincodeOpen(false);
    } catch (error) {
      console.error('Error adding pincodes to database:', error);
      showSnackbar('Error adding pincodes to database', 'error');
    }
  };

  const showSnackbar = (message, severity) => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setSnackbarOpen(true);
  };

  const columns = [
    { field: 'fullName', headerName: 'Full Name', width: 200 },
    { field: 'mobile', headerName: 'Mobile', width: 150 },
    {
      field: 'pincodes',
      headerName: 'Pincodes',
      width: 500, // Increase width to accommodate more data
      renderCell: (params) => (
        <div style={{ whiteSpace: 'normal', wordWrap: 'break-word' }}>
          {params.value.join(', ')}
        </div>
      ),
    },
  ];
  

  return (
    <Box sx={{ height: 400, width: '100%', position: 'relative' }}>
      <Typography variant="h4" gutterBottom>
        Assign Pincodes
      </Typography>
      <DataGrid rows={users} columns={columns} autoHeight  getRowHeight={() => 'auto'}  />
      <Tooltip title="Assign Pincodes">
        <Fab 
          color="primary"
          aria-label="add"
          sx={{ position: 'absolute', top: -28, right: 16 }}
          onClick={handleOpenDialog}
        >
          <AddIcon />
        </Fab>
      </Tooltip>

      <Dialog open={open} onClose={handleCloseDialog} fullWidth maxWidth="sm">
        <DialogTitle>Assign Pincodes</DialogTitle>
        <DialogContent>
          <Autocomplete
            options={users}
            getOptionLabel={(option) => option.fullName}
            value={selectedUser}
            onChange={handleUserChange}
            renderInput={(params) => <TextField {...params} label="Select User" variant="outlined" />}
            sx={{ mb: 2 }}
          />
          {userLevel <= 2 && (
            <>
              <Autocomplete
                options={districts}
                getOptionLabel={(option) => option.name}
                value={selectedDistrict}
                onChange={handleDistrictChange}
                renderInput={(params) => <TextField {...params} label="Select District" variant="outlined" />}
                sx={{ mb: 2 }}
              />
              <Autocomplete
                options={taluks}
                getOptionLabel={(option) => option.name}
                value={selectedTaluk}
                onChange={handleTalukChange}
                renderInput={(params) => <TextField {...params} label="Select Taluk" variant="outlined" />}
                sx={{ mb: 2 }}
              />
              <Button onClick={() => setAddPincodeOpen(true)} variant="outlined" sx={{ mb: 2 }}>
                Add Pincode to DB
              </Button>
            </>
          )}
      <Autocomplete
  multiple
  options={availablePincodes}
  value={selectedPincodes}
  onChange={handlePincodeChange}
  getOptionLabel={(option) => String(option)} // Convert the option to a string
  renderInput={(params) => <TextField {...params} label="Select Pincodes" variant="outlined" />}
  renderTags={(value, getTagProps) =>
    value.map((option, index) => {
      const tagProps = getTagProps({ index });
      delete tagProps.key; // Ensure key is not included in spread props
      return (
        <Chip
          key={option} // Pass key directly here
          label={option}
          {...tagProps}
        />
      );
    })
  }
  sx={{ mb: 2 }}
/>




        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="secondary">
            Cancel
          </Button>
          <Button onClick={handleSubmit} color="primary" variant="contained">
            Submit
          </Button>
        </DialogActions>
      </Dialog>

      {/* Dialog for Adding Pincode to DB */}
      <Dialog open={addPincodeOpen} onClose={() => setAddPincodeOpen(false)} fullWidth maxWidth="sm">
        <DialogTitle>Add Pincode to Database</DialogTitle>
        <DialogContent>
          <Autocomplete
            options={districts}
            getOptionLabel={(option) => option.name}
            value={selectedDistrict}
            onChange={handleDistrictChange}
            renderInput={(params) => <TextField {...params} label="Select District" variant="outlined" />}
            sx={{ mb: 2 }}
          />
          <Autocomplete
            options={taluks}
            getOptionLabel={(option) => option.name}
            value={selectedTaluk}
            onChange={handleTalukChange}
            renderInput={(params) => <TextField {...params} label="Select Taluk" variant="outlined" />}
            sx={{ mb: 2 }}
          />
          <TextField
            label="Add New Pincode"
            variant="outlined"
            fullWidth
            onKeyDown={handleAddNewPincodeChange}
            placeholder="Enter 6-digit pincode and press Enter"
            sx={{ mb: 2 }}
          />
          <Box sx={{ mb: 2 }}>
            {newPincodes.map((pincode, index) => (
              <Chip
                key={index}
                label={pincode}
                onDelete={() => handleDeleteNewPincode(pincode)}
                sx={{ mr: 1, mb: 1 }}
              />
            ))}
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setAddPincodeOpen(false)} color="secondary">
            Cancel
          </Button>
          <Button onClick={handleAddPincodeToDB} color="primary" variant="contained">
            Add to DB
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={() => setSnackbarOpen(false)}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      >
        <Alert
          onClose={() => setSnackbarOpen(false)}
          severity={snackbarSeverity}
          sx={{ width: '100%' }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default AssignPincodes;
