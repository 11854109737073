import React, { useState, useEffect } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { Button, CircularProgress, Box, Typography, Snackbar, Alert } from '@mui/material';
import axios from 'axios';
import { API_BASE_URL } from '../../constants/constants';
import {jwtDecode} from 'jwt-decode';

const ShopQRGenerate = () => {
  const [shops, setShops] = useState([]);
  const [loading, setLoading] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');
  const [page, setPage] = useState(0); // For pagination
  const [pageSize, setPageSize] = useState(10); // Default page size
  const [totalRows, setTotalRows] = useState(0);
  const [userId, setUserId] = useState(null); // User ID from JWT

  useEffect(() => {
    // Extract userId from JWT token stored in localStorage
    const token = localStorage.getItem('token');
    if (token) {
      const decodedToken = jwtDecode(token);
      setUserId(decodedToken.userId);
    }
  }, []);

  useEffect(() => {
    if (userId) {
      fetchShops();
    }
  }, [userId, page, pageSize]);

  const fetchShops = async () => {
    setLoading(true);
    try {
      const response = await axios.get(`${API_BASE_URL}/shops/${userId}`, {
        params: {
          progress: '3', // Filter for shops with progress "3"
          page: page + 1, // Page index (API expects page to start from 1)
          limit: pageSize,
        },
      });

      // Add the sell number to each shop data
      const formattedShops = response.data.shops.map((shop, index) => ({
        ...shop,
        sellNumber: page * pageSize + index + 1, // Incremental sell number
      }));

      setShops(formattedShops); // Set the rows for DataGrid
      setTotalRows(response.data.totalShops);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setSnackbarMessage('Error fetching shops');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    }
  };

  // Handle QR code generation
  const handleGenerateQR = async (shopId) => {
    setLoading(true);
    try {
      await axios.post(`${API_BASE_URL}/onboard/${userId}/${shopId}`);
      setSnackbarMessage('QR Code generated successfully.');
      setSnackbarSeverity('success');
      setSnackbarOpen(true);
    } catch (error) {
      console.error('Error generating QR code:', error);
      setSnackbarMessage('Failed to generate QR code.');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    } finally {
      setLoading(false);
    }
  };

  const columns = [
    { 
      field: 'sellNumber', 
      headerName: 'Sell Number', 
      width: 150,
    },
    { field: 'shopName', headerName: 'Shop Name', width: 200 },
    { field: 'promoterName', headerName: 'Promoter Name', width: 200 },
    { field: 'promoterContact', headerName: 'Promoter Contact', width: 200 },
    {
      field: 'action',
      headerName: 'Action',
      width: 200,
      renderCell: (params) => (
        <Button
          variant="contained"
          color="primary"
          onClick={() => handleGenerateQR(params.row._id)}
        >
          Generate QR Code
        </Button>
      ),
    },
  ];

  return (
    <Box sx={{ height: 400, width: '100%', padding: 2 }}>
      <Typography variant="h4" gutterBottom>
        Shop QR Code Generator
      </Typography>

      {loading ? (
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <CircularProgress />
        </Box>
      ) : (
        <DataGrid
          rows={shops}
          columns={columns}
          pagination
          page={page}
          pageSize={pageSize}
          rowCount={totalRows}
          paginationMode="server"
          onPageChange={(newPage) => setPage(newPage)}
          onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
          getRowId={(row) => row._id}
        />
      )}

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={() => setSnackbarOpen(false)}
      >
        <Alert onClose={() => setSnackbarOpen(false)} severity={snackbarSeverity}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default ShopQRGenerate;
