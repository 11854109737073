import React, { useContext } from 'react';
import { Routes, Route, Navigate, useLocation } from 'react-router-dom';
import Login from './pages/Login';
import AuthContext from './context/AuthContext';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { orange } from '@mui/material/colors';
import Frame from './pages/Frame/Frame';

const theme = createTheme({
  palette: {
    primary: {
      main: orange[500],
    },
    // Add other theme settings as needed
  },
});

const App = () => {
  const { isAuthenticated } = useContext(AuthContext);

  return (
    <ThemeProvider theme={theme}>
      <Routes>
        <Route path="/login" element={<PublicRoute />} />
        <Route path="/*" element={<ProtectedRoutes />} />
      </Routes>
    </ThemeProvider>
  );
};

const PublicRoute = () => {
  const { isAuthenticated, isLoading } = useContext(AuthContext);
  const location = useLocation();

  if (isLoading) {
    return <div>Loading...</div>; // Or a loading spinner
  }

  return isAuthenticated ? <Navigate to={location.state?.from || '/dashboard'} /> : <Login />;
};

const ProtectedRoutes = () => {
  const { isAuthenticated, isLoading } = useContext(AuthContext);
  const location = useLocation();

  if (isLoading) {
    return <div>Loading...</div>; // Or a loading spinner
  }

  return isAuthenticated ? (
    <Frame />
  ) : (
    <Navigate to="/login" state={{ from: location.pathname }} />
  );
};

export default App;
