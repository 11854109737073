import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
  Autocomplete,
  Fab,
  Chip,
  Tooltip,
  Snackbar,
  Alert,
  Dialog as ConfirmDialog,
  DialogTitle as ConfirmDialogTitle,
  DialogActions as ConfirmDialogActions
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { DataGrid } from '@mui/x-data-grid';
import axios from 'axios';
import { API_BASE_URL } from '../../../constants/constants';
import { jwtDecode } from 'jwt-decode';

const CreatePincode = () => {
  const [pincodes, setPincodes] = useState([]);
  const [open, setOpen] = useState(false);
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false); // State for confirmation dialog
  const [districts, setDistricts] = useState([]);
  const [taluks, setTaluks] = useState([]);
  const [selectedDistrict, setSelectedDistrict] = useState(null);
  const [selectedTaluk, setSelectedTaluk] = useState(null);
  const [inputPincodes, setInputPincodes] = useState([]);
  const [newPincode, setNewPincode] = useState('');
  const [error, setError] = useState('');
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarSeverity, setSnackbarSeverity] = useState('error');
  const [message, setMessage] = useState('');
  const [userModules, setUserModules] = useState([]);
  const currentModuleIds = [1210, 1211]; // Current page module IDs

  useEffect(() => {
    fetchPincodes();
    fetchDistricts();
    verifyUserModules();
  }, []);

  const verifyUserModules = () => {
    const token = localStorage.getItem('token');
    const decodedToken = jwtDecode(token);

    if (decodedToken.level === 1 || decodedToken.level === 2) {
      setUserModules(currentModuleIds);
    } else {
      axios.get(`${API_BASE_URL}/privileges/modules/${decodedToken.userId}`)
        .then(response => {
          const modules = response.data.modules || [];
          if (!modules.some(moduleId => currentModuleIds.includes(moduleId))) {
            alert('You are not allowed to use this feature');
            return;
          }
          setUserModules(modules);
        })
        .catch(() => {
          alert('You are not allowed to use this feature');
          return;
        });
    }
  };

  const fetchPincodes = async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/pincodes/details`);
      if (Array.isArray(response.data)) {
        setPincodes(response.data);
      } else {
        setPincodes([]);
      }
    } catch (error) {
      console.error('Error fetching pincodes:', error);
      setPincodes([]);
    }
  };

  const fetchDistricts = async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/districts`);
      setDistricts(response.data);
    } catch (error) {
      console.error('Error fetching districts:', error);
    }
  };

  const fetchTaluks = async (districtId) => {
    try {
      const response = await axios.get(`${API_BASE_URL}/taluks/district/${districtId}`);
      setTaluks(response.data);
    } catch (error) {
      console.error('Error fetching taluks:', error);
    }
  };

  const handleDistrictChange = (event, value) => {
    setSelectedDistrict(value);
    setSelectedTaluk(null);
    setInputPincodes([]);
    setNewPincode('');
    if (value) {
      fetchTaluks(value._id);
    }
  };

  const handleTalukChange = (event, value) => {
    setSelectedTaluk(value);
    setInputPincodes([]);
    setNewPincode('');
  };

  const handleNewPincodeChange = (event) => {
    const value = event.target.value;
    if (value.length <= 6) {
      setNewPincode(value);
    } else {
      setError('Pincode must be 6 digits long.');
      setSnackbarSeverity('error');
      setMessage('Pincode must be 6 digits long.');
      setSnackbarOpen(true);
    }
  };

  const handleAddNewPincode = (event) => {
    if ((event.key === 'Enter' || event.key === ',' || event.type === 'click') && newPincode.length === 6) {
      if (!inputPincodes.includes(newPincode)) {
        setInputPincodes([...inputPincodes, newPincode]);
        setNewPincode('');
      } else {
        setSnackbarSeverity('error');
        setMessage('Pincode already exists.');
        setSnackbarOpen(true);
      }
    } else if (newPincode.length !== 6 && (event.key === 'Enter' || event.key === ',' || event.type === 'click')) {
      setSnackbarSeverity('error');
      setMessage('Invalid pincode. Must be 6 digits.');
      setSnackbarOpen(true);
    }
  };

  const handleOpenDialog = () => {
    setOpen(true);
  };

  const handleCloseDialog = () => {
    if (inputPincodes.length > 0) {
      setConfirmDialogOpen(true); // Open confirmation dialog if there are unsaved changes
    } else {
      clearDialogData();
    }
  };

  const clearDialogData = () => {
    setOpen(false);
    setSelectedDistrict(null);
    setSelectedTaluk(null);
    setInputPincodes([]);
    setNewPincode('');
    setError('');
  };

  const handleSubmit = async () => {
    if (selectedDistrict && selectedTaluk && inputPincodes.length > 0) {
      try {
        const numericPincodes = inputPincodes.map(pincode => Number(pincode));
  
        const response = await axios.post(`${API_BASE_URL}/pincodes`, {
          districtId: selectedDistrict._id,
          talukId: selectedTaluk._id,
          pincodes: numericPincodes,
        });
  
        setSnackbarSeverity('success');
        setMessage(response.data.messages.join('\n'));
        setSnackbarOpen(true);
        clearDialogData();
        fetchPincodes(); // Refresh the pincode list
      } catch (error) {
        console.error('Error submitting pincodes:', error);
        setSnackbarSeverity('error');
        setMessage(error.response.data.message || 'Error submitting pincodes');
        setSnackbarOpen(true);
      }
    } else {
      setSnackbarSeverity('error');
      setMessage('Please fill all fields before submitting.');
      setSnackbarOpen(true);
    }
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const handleConfirmDialogClose = () => {
    setConfirmDialogOpen(false);
  };

  const handleConfirmDialogProceed = () => {
    setConfirmDialogOpen(false);
    clearDialogData();
  };

  const columns = [
    { field: 'districtName', headerName: 'District', width: 190 },
    { field: 'talukName', headerName: 'Taluk', width: 150 },
    {
      field: 'pincodes',
      headerName: 'Pincodes',
      width: 300,
      renderCell: (params) => {
        const pincodes = params.value || [];
        return (
          <div style={{ 
            whiteSpace: 'normal', 
            wordBreak: 'break-word', 
            padding: '8px 0',
            width: '100%',
            height: '100%',
            display: 'flex',
            alignItems: 'center'
          }}>
            <Typography variant="body2" style={{ lineHeight: 1.5 }}>
              {pincodes.join(', ')}
            </Typography>
          </div>
        );
      },
    },
  ];

  const rows = pincodes.map((pincode, index) => ({
    id: index + 1,
    districtName: pincode.districtName,
    talukName: pincode.talukName,
    pincodes: pincode.pincodes,
  }));

  return (
    <Box sx={{ height: 400, width: '100%', position: 'relative' }}>
      <Typography variant="h4" gutterBottom>
        Pincode Management
      </Typography>
      <DataGrid rows={rows} columns={columns} autoHeight  getRowHeight={() => 'auto'} />
      <Tooltip title="Create Pincodes">
        <Fab
          color="primary"
          aria-label="add"
          sx={{ position: 'absolute', top: -7, right: 16 }}
          onClick={handleOpenDialog}
        >
          <AddIcon />
        </Fab>
      </Tooltip>
      <Dialog open={open} onClose={handleCloseDialog} fullWidth maxWidth="sm">
        <DialogTitle>Create New Pincode</DialogTitle>
        <DialogContent>
          <Autocomplete
            options={districts}
            getOptionLabel={(option) => option.name}
            value={selectedDistrict}
            onChange={handleDistrictChange}
            renderInput={(params) => <TextField {...params} label="Select District" variant="outlined" />}
            sx={{ mb: 2 }}
          />
          <Autocomplete
            options={taluks}
            getOptionLabel={(option) => option.name}
            value={selectedTaluk}
            onChange={handleTalukChange}
            renderInput={(params) => <TextField {...params} label="Select Taluk" variant="outlined" />}
            sx={{ mb: 2 }}
          />
          <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
            <TextField
              label="Pincode"
              variant="outlined"
              value={newPincode}
              onChange={handleNewPincodeChange}
              onKeyDown={handleAddNewPincode}
              placeholder="Enter 6-digit pincode"
              sx={{ flexGrow: 1, mr: 2 }}
            />
            <Button onClick={handleAddNewPincode} variant="contained">
              Add Pincode
            </Button>
          </Box>
          <Box sx={{ mb: 2 }}>
            {inputPincodes.map((pincode, index) => (
              <Chip
                key={index}
                label={pincode}
                onDelete={() => setInputPincodes(inputPincodes.filter(pc => pc !== pincode))}
                sx={{ mr: 1, mb: 1 }}
              />
            ))}
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="secondary">
            Cancel
          </Button>
          <Button onClick={handleSubmit} color="primary" variant="contained">
            Submit
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={5000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      >
        <Alert onClose={handleSnackbarClose} severity={snackbarSeverity} sx={{ whiteSpace: 'pre-line' }}>
          {message}
        </Alert>
      </Snackbar>

      {/* Confirmation Dialog for unsaved changes */}
      <ConfirmDialog
        open={confirmDialogOpen}
        onClose={handleConfirmDialogClose}
      >
        <ConfirmDialogTitle>Unsaved Changes</ConfirmDialogTitle>
        <DialogContent>
          <Typography>You have unsaved changes. Do you really want to close?</Typography>
        </DialogContent>
        <ConfirmDialogActions>
          <Button onClick={handleConfirmDialogClose} color="secondary">
            Cancel
          </Button>
          <Button onClick={handleConfirmDialogProceed} color="primary" variant="contained">
            Proceed
          </Button>
        </ConfirmDialogActions>
      </ConfirmDialog>
    </Box>
  );
};

export default CreatePincode;
