import React, { useState, useContext } from 'react';
import { Box, Card, DialogContentText,CardContent, TextField, Button, Typography, Snackbar, Alert, Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { API_BASE_URL } from '../constants/constants';
import AuthContext from '../context/AuthContext';

const Login = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [snackMessage, setSnackMessage] = useState('');
    const [snackOpen, setSnackOpen] = useState(false);
    const [snackSeverity, setSnackSeverity] = useState('success');
    const [dialogOpen, setDialogOpen] = useState(false);
    const [resetEmail, setResetEmail] = useState('');
    const [emailError, setEmailError] = useState('');

    const { login } = useContext(AuthContext);
    const navigate = useNavigate();

    const handleLogin = async () => {
        try {
            const response = await axios.post(`${API_BASE_URL}/users/login`, { email, password });
            login(response.data.user, response.data.token);
            setSnackSeverity('success');
            setSnackMessage('Login successful');
            setSnackOpen(true);
            navigate('/dashboard');
        } catch (err) {
            setSnackSeverity('error');
            setSnackMessage(err.response?.data?.message || 'Login failed');
            setSnackOpen(true);
        }
    };

    const handleResetPassword = async () => {
        try {
            const response = await axios.post(`${API_BASE_URL}/users/reset-password`, { email: resetEmail });
            setSnackSeverity('success');
            setSnackMessage(response.data.message || 'Password reset email sent');
            setSnackOpen(true);
            setDialogOpen(false);
        } catch (err) {
            setSnackSeverity('error');
            setSnackMessage(err.response?.data?.message || 'Failed to reset password');
            setSnackOpen(true);
        }
    };

    const handleSnackClose = () => {
        setSnackOpen(false);
    };

    const openResetDialog = () => {
        setDialogOpen(true);
    };

    const closeResetDialog = () => {
        setDialogOpen(false);
    };

    const confirmResetPassword = () => {
        if (!resetEmail) {
            setEmailError('Email address is required');
            return;
        }
        closeResetDialog();
        handleResetPassword();
    };

    return (
        <Box
            sx={{
                backgroundImage: `url('/images/login-bg.jpg')`,
                backgroundSize: 'cover',
                height: '100vh',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
            }}
        >
            <Card sx={{ width: 400, p: 2 }}>
                <CardContent>
                    <Typography variant="h4" color="primary" gutterBottom align="center">
                        Login to MSB ERP
                    </Typography>
                    <TextField
                        label="Email"
                        fullWidth
                        margin="normal"
                        variant="outlined"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                    />
                    <TextField
                        label="Password"
                        type="password"
                        fullWidth
                        margin="normal"
                        variant="outlined"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                    />
                    <Button
                        variant="contained"
                        color="primary"
                        fullWidth
                        sx={{ mt: 2 }}
                        onClick={handleLogin}
                    >
                        Login
                    </Button>
                    <Button
                        variant="text"
                        color="primary"
                        fullWidth
                        sx={{ mt: 2 }}
                        onClick={openResetDialog}
                    >
                        Reset Password
                    </Button>
                </CardContent>
            </Card>

            <Snackbar
                open={snackOpen}
                autoHideDuration={6000}
                onClose={handleSnackClose}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            >
                <Alert onClose={handleSnackClose} severity={snackSeverity} sx={{ width: '100%' }}>
                    {snackMessage}
                </Alert>
            </Snackbar>

                <Dialog open={dialogOpen} onClose={closeResetDialog}>
                    <DialogTitle sx={{color: 'primary.main'}}>Reset Password</DialogTitle>
                    <DialogContentText sx={{ m: 2 }}>
                        To reset your password, please enter your email address here. We
                        will send a new password to your email.
                    </DialogContentText>
                    <DialogContent>
                        <TextField
                            autoFocus
                            margin="dense"
                            label="Email Address"
                            type="email"
                            fullWidth
                            variant="standard"
                            value={resetEmail}
                            onChange={(e) => {
                                setResetEmail(e.target.value);
                                setEmailError(''); // Clear the error when the user starts typing
                            }}
                            error={!!emailError}
                            helperText={emailError}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={closeResetDialog} color="primary">
                            Cancel
                        </Button>
                        <Button onClick={confirmResetPassword} color="primary">
                            Confirm
                        </Button>
                    </DialogActions>
                </Dialog>

        </Box>
    );
};

export default Login;
