import React, { useState, useEffect, useCallback } from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
  Fab,
  Tooltip,
  Snackbar,
  Alert,
  Autocomplete,
  Chip,
} from '@mui/material';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import { DataGrid } from '@mui/x-data-grid';
import axios from 'axios';
import { API_BASE_URL } from '../../../constants/constants';
import {jwtDecode} from 'jwt-decode';
import Loader from '../../General/Loader';

const RemoveAssignedPrivileges = () => {
  const [users, setUsers] = useState([]);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [modules, setModules] = useState([]);
  const [selectedModulesToRemove, setSelectedModulesToRemove] = useState([]);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');
  const [userLevel, setUserLevel] = useState(null);
  const [userId, setUserId] = useState(null);
  const [hasPrivilege, setHasPrivilege] = useState(false);

  const PRIVILEGE_MODULE = 1316;

  const showSnackbar = useCallback((message, severity) => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setSnackbarOpen(true);
  }, []);

  const fetchUsers = useCallback(async (userId) => {
    setLoading(true);
    try {
      const response = await axios.get(`${API_BASE_URL}/users-with-pincodes/${userId}`);
      const usersWithModules = await Promise.all(
        response.data.map(async (user) => {
          try {
            const privileges = await axios.get(`${API_BASE_URL}/privileges/${user.id}`);
            return {
              ...user,
              id: user.id,
              modules: privileges.data.modules || [],
            };
          } catch (error) {
            console.error(`Error fetching privileges for user ${user.id}:`, error);
            return user;
          }
        })
      );
      setUsers(usersWithModules);
    } catch (error) {
      console.error('Error fetching users:', error);
      showSnackbar('Error fetching users', 'error');
    } finally {
      setLoading(false);
    }
  }, [showSnackbar]);

  const checkPrivilege = useCallback(async (decodedToken) => {
    if (decodedToken.level <= 2) {
      setHasPrivilege(true);
    } else {
      try {
        const response = await axios.get(`${API_BASE_URL}/privileges/modules/${decodedToken.userId}`);
        setHasPrivilege(response.data.modules.includes(PRIVILEGE_MODULE));
      } catch (error) {
        console.error('Error checking privilege:', error);
        setHasPrivilege(false);
        showSnackbar('You do not have permission to remove privileges', 'error');
      }
    }
  }, [showSnackbar]);

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      const decodedToken = jwtDecode(token);
      setUserLevel(decodedToken.level);
      setUserId(decodedToken.userId);
      fetchUsers(decodedToken.userId);
      checkPrivilege(decodedToken);
    }
  }, [fetchUsers, checkPrivilege]);

  const handleOpenDialog = () => {
    if (!hasPrivilege) {
      showSnackbar('You do not have permission to remove privileges', 'error');
      return;
    }
    setOpen(true);
  };

  const handleCloseDialog = () => {
    setOpen(false);
    resetDialogState();
  };

  const resetDialogState = () => {
    setSelectedUser(null);
    setSelectedModulesToRemove([]);
  };
  const handleModuleSelectionChange = (event, value) => {
    const selectedModules = value.map(module => {
    
        return { 
            moduleId: module.id,  // Ensure you're using 'id' which corresponds to 'moduleId'
            name: module.name 
        };
    });

    setSelectedModulesToRemove(selectedModules);

  
};

const handleUserChange = async (event, value) => {
    setSelectedUser(value);
    if (value) {
        try {
            const { data } = await axios.get(`${API_BASE_URL}/privileges/${value.id}`);
            const userModules = data?.modules || [];

         

            setSelectedModulesToRemove(userModules.map(module => ({
                moduleId: module.id, // Correctly map 'id' to 'moduleId'
                name: module.name,
            })));

            
        } catch (error) {
            console.error('Error fetching user privileges:', error);
            showSnackbar('Error fetching user privileges', 'error');
        }
    } else {
        setSelectedModulesToRemove([]);
    }
};

 

  const handleModuleChange = (event, newValue) => {
    setSelectedModulesToRemove(newValue);
  };

 
  const selectedModuleIds = selectedModulesToRemove.map(m => m.moduleId);

  

  const handleSubmit = async () => {
    if (!selectedUser) {
        showSnackbar('Please select a user', 'error');
        return;
    }

    if (selectedModulesToRemove.length === 0) {
        showSnackbar('Please select modules to remove', 'error');
        return;
    }

    const removeModules = selectedModulesToRemove.map(module => module.moduleId);

    if (removeModules.some(id => id === undefined)) {
        console.error('Error: Some module IDs are undefined. Check the selected modules structure.');
        showSnackbar('Error preparing request. Please try again.', 'error');
        return;
    }

    const payload = { removeModules };


    try {
        const url = `${API_BASE_URL}/privileges/${selectedUser.id}`;


        const response = await axios.put(url, payload);


        showSnackbar('Modules removed successfully', 'success');
        handleCloseDialog();
        await fetchUsers(userId);
    } catch (error) {
        console.error('Error removing privileges:', error);
        showSnackbar('Error removing privileges', 'error');
    }
};




  const getModuleNames = (modules) => {
    if (!Array.isArray(modules) || modules.length === 0) {
      return 'No modules assigned';
    }
    return modules.map((module) => module.name || `Module ID: ${module.id}`).join(', ');
  };

  const columns = [
    { field: 'fullName', headerName: 'Full Name', width: 200, flex: 1 },
    { field: 'mobile', headerName: 'Mobile', width: 150, flex: 1 },
    {
      field: 'modules',
      headerName: 'Modules',
      width: 400,
      flex: 2,
      renderCell: (params) => {
        try {
          return (
            <div style={{ whiteSpace: 'normal', wordWrap: 'break-word', wordBreak: 'break-word', lineHeight: '1.2' }}>
              {getModuleNames(params.value)}
            </div>
          );
        } catch (error) {
          console.error('Error rendering modules:', error, params.value);
          return 'Error displaying modules';
        }
      },
    },
  ];

  return (
    <Box sx={{ width: '100%', height: 'auto', position: 'relative', mt: 4 }}>
      <Typography variant="h4" gutterBottom>
        Remove Assigned Privileges
      </Typography>
      {loading ? (
        <Loader />
      ) : (
        <>
          <Box sx={{ height: 500, width: '100%' }}>
            <DataGrid 
              rows={users} 
              columns={columns} 
              pageSize={20} 
              getRowId={(row) => row.id}
              disableSelectionOnClick
              sx={{
                '& .MuiDataGrid-cell': {
                  whiteSpace: 'normal !important',
                  wordWrap: 'break-word !important',
                  lineHeight: '2.5 !important',
                  height: 'auto !important',
                  alignItems: 'flex-start !important',
                },
                '& .MuiDataGrid-row': {
                  maxHeight: 'none !important',
                  height: 'auto !important',
                },
              }}
            />
          </Box>
          <Tooltip title="Remove Privileges">
            <Fab
              color="primary"
              aria-label="remove"
              sx={{ position: 'absolute', top: -28, right: 16 }}
              onClick={handleOpenDialog}
            >
              <RemoveCircleOutlineIcon />
            </Fab>
          </Tooltip>
        </>
      )}

      <Dialog open={open} onClose={handleCloseDialog} fullWidth maxWidth="sm">
        <DialogTitle>Remove Assigned Privileges</DialogTitle>
        <DialogContent>
          <Autocomplete
            options={users}
            getOptionLabel={(option) => option.fullName}
            value={selectedUser}
            onChange={handleUserChange}
            renderInput={(params) => <TextField {...params} label="Select User" variant="outlined" />}
            sx={{ mb: 2 }}
          />
          <Autocomplete
            multiple
            options={modules}
            getOptionLabel={(option) => option.name}
            value={selectedModulesToRemove}
            onChange={handleModuleChange}
            isOptionEqualToValue={(option, value) => option.name === value.name}
            renderInput={(params) => <TextField {...params} label="Select Modules to Remove" variant="outlined" />}
            renderTags={(value, getTagProps) =>
              value.map((option, index) => (
                <Chip
                  key={option._id || index}
                  label={option.name}
                  {...getTagProps({ index })}
                />
              ))
            }
            sx={{ mb: 2 }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="secondary">
            Cancel
          </Button>
          <Button onClick={handleSubmit} color="primary" variant="contained">
            Remove
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={() => setSnackbarOpen(false)}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      >
        <Alert
          onClose={() => setSnackbarOpen(false)}
          severity={snackbarSeverity}
          sx={{ width: '100%' }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default RemoveAssignedPrivileges;
