import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Dashboard from '../Dashboard';
import Reports from '../Reports';
import NotFound from '../General/NotFound';
import Profile from '../Personal/Profile';
import AdminControlManagement from '../AdminControl/AdminControlManagement';

import PincodeManagement  from '../AdminControl/Pincodes/PincodeManagement';
import CreatePincode from '../AdminControl/Pincodes/CreatePincode';
import DeletePincode from '../AdminControl/Pincodes/DeletePincode';


import AdminManagement from '../AdminControl/AdminCreation/AdminManagement';
import CreateAdmin from '../AdminControl/AdminCreation/CreateAdmin';
import AssignPincodes from '../AdminControl/AdminCreation/AssignPincodes';
import AssignPrivileges from '../AdminControl/AdminCreation/AssignPrivilages';
import RemoveAssignedPrivileges from '../AdminControl/AdminCreation/RemoveAssignedPrivilages';

import ShopEnquiry from '../ShopEnquiry/ShopEnquiry';
import ShopEnquiryAction from '../ShopEnquiry/ShopEnquiryAction';
import ShopEnquiryManagement from '../ShopEnquiry/ShopEnquiryManagement';

import ShopManagement from '../Shop/ShopManagement';
import AddShop from '../Shop/AddShop';
import AllShops from '../Shop/AllShops';
import ShopEdit from '../Shop/ShopEdit';
import VerifyDocuments from '../Shop/VerifyDocuments';
import SetShopCommission from '../Shop/SetShopCommission';
import ShopQRGenerate from '../Shop/ShopQRGenerate';

import ManageShopReport from '../ShopReport/ManageShopReport';



import ManageMsbReport from '../MsbReport/ManageMsbReport';



import ManageCustomerReport from '../CustomerReport/ManageCustomerReport';



import ManageAdminReport from '../AdminReport/ManageAdminReport';


// Import other components as needed

const Router = () => {
    return (
        <Routes>
            <Route path="/" element={<Dashboard />} />
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/reports" element={<Reports />} />
            <Route path="/profile" element={<Profile />} />
            <Route path="/adminControl" element={<AdminControlManagement />} />
            <Route path="*" element={<NotFound />} />

            <Route path="/adminControl/pincodes" element={<PincodeManagement />} />
            <Route path="/adminControl/pincodes/create" element={<CreatePincode />} />
            <Route path="/adminControl/pincodes/delete" element={<DeletePincode />} />

            <Route path="/adminControl/admins" element={<AdminManagement />} />
            <Route path="/adminControl/admins/create" element={<CreateAdmin />} />
            <Route path="/adminControl/admins/assign-pincodes" element={<AssignPincodes />} />
            <Route path="/adminControl/admins/assign-privileges" element={<AssignPrivileges />} />
            <Route path="/adminControl/admins/remove-privilages" element={<RemoveAssignedPrivileges />} />


            <Route path="/shopEnquiry" element={<ShopEnquiryManagement />} />

            <Route path="/addShop" element={<ShopManagement />} />
            <Route path="/shop/create" element={<AddShop />} />
            <Route path="/shop/all-shops" element={<AllShops />} />
            <Route path="/shop/edit/:id" element={<ShopEdit />} />
            <Route path='/shop/verify-documents' element={<VerifyDocuments />} />
            <Route path='/shop/set-commission' element={<SetShopCommission />} />
            <Route path='/shop/generate-qr-code' element={<ShopQRGenerate />} />


            <Route path='/shopReport' element={<ManageShopReport />} />




            <Route path='/msbReport' element={<ManageMsbReport />} />





            <Route path='/customerReport' element={<ManageCustomerReport />} />


            <Route path='/adminReport' element={<ManageAdminReport />} />


            {/* Add more routes as needed */}
        </Routes>
    );
};

export default Router;
