import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
  Snackbar,
  Alert,
  IconButton,
  DialogContentText,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import axios from 'axios';
import { DataGrid } from '@mui/x-data-grid';
import { API_BASE_URL } from '../../constants/constants';
import { jwtDecode } from 'jwt-decode';

const ShopEnquiry = () => {
  const [enquiries, setEnquiries] = useState([]);
  const [open, setOpen] = useState(false);
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [selectedEnquiry, setSelectedEnquiry] = useState(null);
  const [editingEnquiry, setEditingEnquiry] = useState(null);
  const [shopName, setShopName] = useState('');
  const [address, setAddress] = useState('');
  const [pincode, setPincode] = useState('');
  const [mobileNumber, setMobileNumber] = useState('');
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');
  const [userId, setUserId] = useState(null);
  const [userLevel, setUserLevel] = useState(null);

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      const decodedToken = jwtDecode(token);
      setUserId(decodedToken.userId);
      setUserLevel(decodedToken.level);
      fetchEnquiries(decodedToken.userId);
    }
  }, []);

  const fetchEnquiries = async (userId) => {
    try {
      const response = await axios.get(`${API_BASE_URL}/enquiries/${userId}`);
      setEnquiries(response.data);
    } catch (error) {
      console.error('Error fetching enquiries', error);
    }
  };

  const handleSubmit = async () => {
    const payload = { shopName, address, pincode, mobileNumber };
    try {
      if (editingEnquiry) {
        await axios.put(`${API_BASE_URL}/enquiries/${userId}/${editingEnquiry._id}`, payload);
        setSnackbarMessage('Enquiry updated successfully');
      } else {
        await axios.post(`${API_BASE_URL}/enquiries/${userId}`, payload);
        setSnackbarMessage('Enquiry added successfully');
      }
      setSnackbarSeverity('success');
      setSnackbarOpen(true);
      fetchEnquiries(userId);
      handleClose();
    } catch (error) {
      if (error.response && error.response.status === 403) {
        setSnackbarMessage(error.response.data.message || 'You do not have permission for this action');
      } else {
        setSnackbarMessage('Error submitting enquiry');
      }
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    }
  };

  const handleConfirmDialogOpen = (enquiry) => {
    setSelectedEnquiry(enquiry);
    setEditingEnquiry(enquiry);
    setShopName(enquiry.shopName);
    setAddress(enquiry.address);
    setPincode(enquiry.pincode);
    setMobileNumber(enquiry.mobileNumber);
    setConfirmDelete(false);
    setOpen(true);
  };

  const handleConfirmDelete = (enquiry) => {
    setSelectedEnquiry(enquiry);
    setConfirmDelete(true);
    setConfirmDialogOpen(true);
  };

  const handleDelete = async () => {
    if (!selectedEnquiry) return;
    try {
      await axios.delete(`${API_BASE_URL}/enquiries/${userId}/${selectedEnquiry._id}`);
      setSnackbarMessage('Enquiry deleted successfully');
      setSnackbarSeverity('success');
      setSnackbarOpen(true);
      fetchEnquiries(userId);
      handleCloseConfirmDialog();
    } catch (error) {
      setSnackbarMessage(error.response?.data?.message || 'Error deleting enquiry');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    }
  };

  const handleCloseConfirmDialog = () => {
    setConfirmDialogOpen(false);
    setSelectedEnquiry(null);
  };

  const handleClose = () => {
    setOpen(false);
    setEditingEnquiry(null);
    setShopName('');
    setAddress('');
    setPincode('');
    setMobileNumber('');
  };

  return (
    <Box sx={{ width: '100%', p: 3 }}>
      <Typography variant="h4">Shop Enquiry</Typography>
      <Button
        variant="contained"
        startIcon={<AddIcon />}
        onClick={() => setOpen(true)}
        sx={{ mb: 3 }}
      >
        Add Enquiry
      </Button>

      <DataGrid
        rows={enquiries}
        getRowId={(row) => row._id}
        columns={[
          { field: 'shopName', headerName: 'Shop Name', width: 200 },
          { field: 'address', headerName: 'Address', width: 200 },
          { field: 'pincode', headerName: 'Pincode', width: 150 },
          { field: 'mobileNumber', headerName: 'Mobile Number', width: 150 },
          { field: 'status', headerName: 'Status', width: 150 },
          {
            field: 'actions',
            headerName: 'Actions',
            renderCell: (params) => (
              <>
                <IconButton onClick={() => handleConfirmDialogOpen(params.row)}>
                  <EditIcon />
                </IconButton>
                <IconButton onClick={() => handleConfirmDelete(params.row)}>
                  <DeleteIcon />
                </IconButton>
              </>
            ),
          },
        ]}
        pageSize={5}
      />

      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>{editingEnquiry ? 'Edit' : 'Add'} Enquiry</DialogTitle>
        <DialogContent>
          <TextField
            label="Shop Name"
            value={shopName}
            onChange={(e) => setShopName(e.target.value)}
            fullWidth
            sx={{ mb: 2 }}
          />
          <TextField
            label="Address"
            value={address}
            onChange={(e) => setAddress(e.target.value)}
            fullWidth
            sx={{ mb: 2 }}
          />
          <TextField
            label="Pincode"
            value={pincode}
            onChange={(e) => setPincode(e.target.value)}
            fullWidth
            sx={{ mb: 2 }}
          />
          <TextField
            label="Mobile Number"
            value={mobileNumber}
            onChange={(e) => setMobileNumber(e.target.value)}
            fullWidth
            sx={{ mb: 2 }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleSubmit}>{editingEnquiry ? 'Update' : 'Add'}</Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={confirmDialogOpen}
        onClose={handleCloseConfirmDialog}
      >
        <DialogTitle>
          {confirmDelete ? 'Confirm Delete' : 'Confirm Edit'}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to {confirmDelete ? 'delete' : 'edit'} this enquiry?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseConfirmDialog}>Cancel</Button>
          <Button
            onClick={confirmDelete ? handleDelete : handleSubmit}
            color="primary"
            variant="contained"
          >
            {confirmDelete ? 'Delete' : 'Edit'}
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={() => setSnackbarOpen(false)}
      >
        <Alert
          onClose={() => setSnackbarOpen(false)}
          severity={snackbarSeverity}
          sx={{ width: '100%' }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default ShopEnquiry;