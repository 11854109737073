import React, { useState, useEffect } from 'react';
import { jwtDecode } from 'jwt-decode';
import ShopEnquiry from './ShopEnquiry';
import ShopEnquiryAction from './ShopEnquiryAction';
import { Typography, Box } from '@mui/material';

const ShopEnquiryManagement = () => {
  const [userLevel, setUserLevel] = useState(null);

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      try {
        const decodedToken = jwtDecode(token);
        setUserLevel(decodedToken.level);
    
      } catch (error) {
        console.error('Error decoding token:', error);
        setUserLevel(null);
      }
    }
  }, []);

  if (userLevel === null) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <Typography variant="h6">Loading...</Typography>
      </Box>
    );
  }

  if (userLevel === 5) {
    return <ShopEnquiry />;
  } else if (userLevel === 4) {
    return <ShopEnquiryAction />;
  } else {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <Typography variant="h6">You do not have permission to access this page.</Typography>
      </Box>
    );
  }
};

export default ShopEnquiryManagement;