import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import {
  Box,
  TextField,
  Button,
  Typography,
  Grid,
  Paper,
  CircularProgress,
  Snackbar,
  Alert,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@mui/material';
import axios from 'axios';
import { API_BASE_URL } from '../../constants/constants';
import { jwtDecode } from 'jwt-decode';

const ShopEdit = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'info' });
  const [userId, setUserId] = useState(null);

  // Shop details state
  const [shopName, setShopName] = useState('');
  const [shopAddress, setShopAddress] = useState('');
  const [shopType, setShopType] = useState('');
  const [shopPincode, setShopPincode] = useState('');
  const [shopLicanceNumber, setShopLicanceNumber] = useState('');
  const [shopGstNumber, setShopGstNumber] = useState('');
  const [shopEmail, setShopEmail] = useState('');
  const [promoterName, setPromoterName] = useState('');
  const [promoterContact, setPromoterContact] = useState('');
  const [promoterAdhaar, setPromoterAdhaar] = useState('');
  const [promoterPan, setPromoterPan] = useState('');
  const [promoterWhatsApp, setPromoterWhatsApp] = useState('');
  const [promoterEmail, setPromoterEmail] = useState('');
  const [shopAccountNumber, setShopAccountNumber] = useState('');
  const [shopBank, setShopBank] = useState('');
  const [shopBranch, setShopBranch] = useState('');
  const [shopIFSC, setShopIFSC] = useState('');

  // File state
  const [shopLicance, setShopLicance] = useState('');
  const [shopGstCertificate, setShopGstCertificate] = useState('');
  const [adhaar, setAdhaar] = useState('');
  const [pan, setPan] = useState('');
  const [bankProof, setBankProof] = useState('');
  const [shopPhoto, setShopPhoto] = useState('');

  // File name state
  const [shopLicanceName, setShopLicanceName] = useState('');
  const [shopGstCertificateName, setShopGstCertificateName] = useState('');
  const [adhaarName, setAdhaarName] = useState('');
  const [panName, setPanName] = useState('');
  const [bankProofName, setBankProofName] = useState('');
  const [shopPhotoName, setShopPhotoName] = useState('');

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      const decodedToken = jwtDecode(token);
      setUserId(decodedToken.userId);
    }
  }, []);

  useEffect(() => {
    if (userId && id) {
      fetchShop();
    }
  }, [userId, id]);

  const fetchShop = async () => {
    if (!id) {
      setSnackbar({ open: true, message: 'Shop ID is missing', severity: 'error' });
      setLoading(false);
      return;
    }

    try {
      const response = await axios.get(`${API_BASE_URL}/shops/${userId}/${id}`);
      const shop = response.data;
      
      // Set shop details
      setShopName(shop.shopName);
      setShopAddress(shop.shopAddress);
      setShopType(shop.shopType);
      setShopPincode(shop.shopPincode);
      setShopLicanceNumber(shop.shopLicanceNumber);
      setShopGstNumber(shop.shopGstNumber);
      setShopEmail(shop.shopEmail);
      setPromoterName(shop.promoterName);
      setPromoterContact(shop.promoterContact);
      setPromoterAdhaar(shop.promoterAdhaar);
      setPromoterPan(shop.promoterPan);
      setPromoterWhatsApp(shop.promoterWhatsApp);
      setPromoterEmail(shop.promoterEmail);
      setShopAccountNumber(shop.shopAccountNumber);
      setShopBank(shop.shopBank);
      setShopBranch(shop.shopBranch);
      setShopIFSC(shop.shopIFSC);

      // Set file names
      setShopLicanceName(shop.shopLicanceName || '');
      setShopGstCertificateName(shop.shopGstCertificateName || '');
      setAdhaarName(shop.adhaarName || '');
      setPanName(shop.panName || '');
      setBankProofName(shop.bankProofName || '');
      setShopPhotoName(shop.shopPhotoName || '');

    } catch (error) {
      console.error('Error fetching shop:', error);
      setSnackbar({ 
        open: true, 
        message: `Error fetching shop details: ${error.response?.data?.message || error.message}`, 
        severity: 'error' 
      });
    } finally {
      setLoading(false);
    }
  };

  const handleFileUpload = (e, setFile, setFileName) => {
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.onloadend = () => {
      setFile(reader.result);
      setFileName(file.name);
    };
    reader.readAsDataURL(file);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const updatedShop = {
        shopName,
        shopAddress,
        shopType,
        shopPincode,
        shopLicanceNumber,
        shopGstNumber,
        shopEmail,
        promoterName,
        promoterContact,
        promoterAdhaar,
        promoterPan,
        promoterWhatsApp,
        promoterEmail,
        shopAccountNumber,
        shopBank,
        shopBranch,
        shopIFSC,
        shopLicance,
        shopGstCertificate,
        adhaar,
        pan,
        bankProof,
        shopPhoto,
        shopLicanceName,
        shopGstCertificateName,
        adhaarName,
        panName,
        bankProofName,
        shopPhotoName,
        progress: "1",
        verificationStatus: "notVerified"
      };
      await axios.put(`${API_BASE_URL}/shops/${userId}/${id}`, updatedShop);
      setSnackbar({ open: true, message: 'Shop updated successfully', severity: 'success' });
      setTimeout(() => navigate('/shop/all-shops'), 2000);
    } catch (error) {
      console.error('Error updating shop:', error);
      setSnackbar({ 
        open: true, 
        message: `Error updating shop: ${error.response?.data?.message || error.message}`, 
        severity: 'error' 
      });
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh">
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box sx={{ width: '100%', p: 3 }}>
      <Paper elevation={3} sx={{ p: 3 }}>
        <Typography variant="h4" gutterBottom>
          Edit Shop
        </Typography>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Shop Name"
                value={shopName}
                onChange={(e) => setShopName(e.target.value)}
                required
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Shop Address"
                value={shopAddress}
                onChange={(e) => setShopAddress(e.target.value)}
                required
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth>
                <InputLabel>Shop Type</InputLabel>
                <Select
                  value={shopType}
                  onChange={(e) => setShopType(e.target.value)}
                  required
                >
                  <MenuItem value="Grocery">Grocery</MenuItem>
                  <MenuItem value="Electronics">Electronics</MenuItem>
                  <MenuItem value="Clothing">Clothing</MenuItem>
                  <MenuItem value="Food">Food</MenuItem>
                  {/* Add more shop types as needed */}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Shop Pincode"
                value={shopPincode}
                onChange={(e) => setShopPincode(e.target.value)}
                required
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Shop License Number"
                value={shopLicanceNumber}
                onChange={(e) => setShopLicanceNumber(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Shop GST Number"
                value={shopGstNumber}
                onChange={(e) => setShopGstNumber(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Shop Email"
                value={shopEmail}
                onChange={(e) => setShopEmail(e.target.value)}
                type="email"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Promoter Name"
                value={promoterName}
                onChange={(e) => setPromoterName(e.target.value)}
                required
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Promoter Contact"
                value={promoterContact}
                onChange={(e) => setPromoterContact(e.target.value)}
                required
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Promoter Aadhar"
                value={promoterAdhaar}
                onChange={(e) => setPromoterAdhaar(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Promoter PAN"
                value={promoterPan}
                onChange={(e) => setPromoterPan(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Promoter WhatsApp"
                value={promoterWhatsApp}
                onChange={(e) => setPromoterWhatsApp(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Promoter Email"
                value={promoterEmail}
                onChange={(e) => setPromoterEmail(e.target.value)}
                type="email"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Shop Account Number"
                value={shopAccountNumber}
                onChange={(e) => setShopAccountNumber(e.target.value)}
                required
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Shop Bank"
                value={shopBank}
                onChange={(e) => setShopBank(e.target.value)}
                required
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Shop Branch"
                value={shopBranch}
                onChange={(e) => setShopBranch(e.target.value)}
                required
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Shop IFSC"
                value={shopIFSC}
                onChange={(e) => setShopIFSC(e.target.value)}
                required
              />
            </Grid>

            {/* File upload fields */}
            <Grid item xs={12} sm={6}>
              <input
                accept="image/*,application/pdf"
                style={{ display: 'none' }}
                id="shop-licance"
                type="file"
                onChange={(e) => handleFileUpload(e, setShopLicance, setShopLicanceName)}
              />
              <label htmlFor="shop-licance">
                <Button variant="contained" component="span" fullWidth>
                  Upload Shop License
                </Button>
              </label>
              {shopLicanceName && <Typography>{shopLicanceName}</Typography>}
            </Grid>
            <Grid item xs={12} sm={6}>
              <input
                accept="image/*,application/pdf"
                style={{ display: 'none' }}
                id="shop-gst-certificate"
                type="file"
                onChange={(e) => handleFileUpload(e, setShopGstCertificate, setShopGstCertificateName)}
              />
              <label htmlFor="shop-gst-certificate">
                <Button variant="contained" component="span" fullWidth>
                  Upload GST Certificate
                </Button>
              </label>
              {shopGstCertificateName && <Typography>{shopGstCertificateName}</Typography>}
            </Grid>
            <Grid item xs={12} sm={6}>
              <input
                accept="image/*,application/pdf"
                style={{ display: 'none' }}
                id="adhaar"
                type="file"
                onChange={(e) => handleFileUpload(e, setAdhaar, setAdhaarName)}
              />
              <label htmlFor="adhaar">
                <Button variant="contained" component="span" fullWidth>
                  Upload Aadhar
                </Button>
              </label>
              {adhaarName && <Typography>{adhaarName}</Typography>}
            </Grid>
            <Grid item xs={12} sm={6}>
              <input
                accept="image/*,application/pdf"
                style={{ display: 'none' }}
                id="pan"
                type="file"
                onChange={(e) => handleFileUpload(e, setPan, setPanName)}
              />
              <label htmlFor="pan">
                <Button variant="contained" component="span" fullWidth>
                  Upload PAN
                </Button>
              </label>
              {panName && <Typography>{panName}</Typography>}
            </Grid>
            <Grid item xs={12} sm={6}>
              <input
                accept="image/*,application/pdf"
                style={{ display: 'none' }}
                id="bank-proof"
                type="file"
                onChange={(e) => handleFileUpload(e, setBankProof, setBankProofName)}
              />
              <label htmlFor="bank-proof">
                <Button variant="contained" component="span" fullWidth>
                  Upload Bank Proof
                </Button>
              </label>
              {bankProofName && <Typography>{bankProofName}</Typography>}
            </Grid>
            <Grid item xs={12} sm={6}>
              <input
                accept="image/*"
                style={{ display: 'none' }}
                id="shop-photo"
                type="file"
                onChange={(e) => handleFileUpload(e, setShopPhoto, setShopPhotoName)}
              />
              <label htmlFor="shop-photo">
                <Button variant="contained" component="span" fullWidth>
                  Upload Shop Photo
                </Button>
              </label>
              {shopPhotoName && <Typography>{shopPhotoName}</Typography>}
            </Grid>

            <Grid item xs={12}>
              <Button 
                type="submit" 
                variant="contained" 
                color="primary" 
                fullWidth
                disabled={loading}
              >
                {loading ? 'Updating...' : 'Update Shop'}
              </Button>
            </Grid>
          </Grid>
        </form>
      </Paper>
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
      >
        <Alert onClose={() => setSnackbar({ ...snackbar, open: false })} severity={snackbar.severity}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default ShopEdit;