import React, { useState, useEffect, useContext } from 'react';
import { Box, Typography, TextField, Button, Dialog, DialogActions, DialogContent, DialogTitle, Snackbar, Alert, Card } from '@mui/material';
import AuthContext from '../../context/AuthContext';
import axios from 'axios';
import { API_BASE_URL } from '../../constants/constants';
import Loader from '../General/Loader';
import {jwtDecode} from 'jwt-decode';

const Profile = () => {
  const [profileData, setProfileData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [snackOpen, setSnackOpen] = useState(false);
  const [snackMessage, setSnackMessage] = useState('');
  const [snackSeverity, setSnackSeverity] = useState('success');

  const [passwordDialogOpen, setPasswordDialogOpen] = useState(false);
  const [emailDialogOpen, setEmailDialogOpen] = useState(false);

  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [passwordStrength, setPasswordStrength] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  const [currentEmail, setCurrentEmail] = useState('');
  const [newEmail, setNewEmail] = useState('');
  const [confirmEmail, setConfirmEmail] = useState('');

  const { token } = useContext(AuthContext);

  useEffect(() => {
    const fetchProfile = async () => {
      try {
        const decoded = jwtDecode(token);
        const response = await axios.get(`${API_BASE_URL}/users/${decoded.userId}`);
        setProfileData(response.data);
      } catch (err) {
        setError('Failed to load profile data');
        setSnackSeverity('error');
        setSnackMessage('Failed to load profile data');
        setSnackOpen(true);
      } finally {
        setLoading(false);
      }
    };

    fetchProfile();
  }, [token]);

  const validatePassword = (password) => {
    const strongPasswordRegex = /^(?=.*[a-z]{3,})(?=.*[A-Z]{4,})(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{10,}$/;
    if (strongPasswordRegex.test(password)) {
      setPasswordStrength('Strong');
    } else if (password.length >= 10) {
      setPasswordStrength('Medium');
    } else {
      setPasswordStrength('Weak');
    }
  };

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handlePasswordChange = async () => {
    if (newPassword === currentPassword) {
      setSnackSeverity('error');
      setSnackMessage('New password cannot be the same as the current password');
      setSnackOpen(true);
      return;
    }

    if (newPassword !== confirmPassword) {
      setSnackSeverity('error');
      setSnackMessage('Passwords do not match');
      setSnackOpen(true);
      return;
    }

    try {
      const response = await axios.post(`${API_BASE_URL}/users/change-password`, {
        email: profileData.email,
        currentPassword,
        newPassword,
      });
      setSnackSeverity('success');
      setSnackMessage(response.data.message || 'Password changed successfully');
    } catch (err) {
      setSnackSeverity('error');
      setSnackMessage(err.response?.data?.message || 'Failed to change password');
    } finally {
      setSnackOpen(true);
      setPasswordDialogOpen(false);
      setCurrentPassword('');
      setNewPassword('');
      setConfirmPassword('');
    }
  };

  const handleEmailChange = async () => {
    if (newEmail === currentEmail) {
      setSnackSeverity('error');
      setSnackMessage('New email cannot be the same as the current email');
      setSnackOpen(true);
      return;
    }

    if (!validateEmail(newEmail)) {
      setSnackSeverity('error');
      setSnackMessage('Please enter a valid email address');
      setSnackOpen(true);
      return;
    }

    if (newEmail !== confirmEmail) {
      setSnackSeverity('error');
      setSnackMessage('Emails do not match');
      setSnackOpen(true);
      return;
    }

    try {
      const response = await axios.post(`${API_BASE_URL}/users/change-email`, {
        currentEmail: profileData.email,
        currentPassword,
        newEmail,
      });
      setSnackSeverity('success');
      setSnackMessage(response.data.message || 'Email changed successfully');
      setProfileData(prevData => ({ ...prevData, email: newEmail }));
    } catch (err) {
      setSnackSeverity('error');
      setSnackMessage(err.response?.data?.message || 'Failed to change email');
    } finally {
      setSnackOpen(true);
      setEmailDialogOpen(false);
      setCurrentPassword('');
      setNewEmail('');
      setConfirmEmail('');
    }
  };

  const handleSnackClose = () => {
    setSnackOpen(false);
  };

  if (loading) return <Loader />;

  return (
    <Box sx={{ maxWidth: 600, mx: 'auto', mt: 5 }}>
      <Card sx={{ p: 3, boxShadow: 3 }}>
        <Typography variant="h4" gutterBottom sx={{ fontFamily: 'Merriweather, serif' }}>
          My Profile
        </Typography>
        {error && <Typography color="error">{error}</Typography>}
        <TextField
          label="First Name"
          fullWidth
          margin="normal"
          variant="outlined"
          value={profileData?.firstName || ''}
          disabled
        />
        <TextField
          label="Last Name"
          fullWidth
          margin="normal"
          variant="outlined"
          value={profileData?.lastName || ''}
          disabled
        />
        <TextField
          label="Email"
          fullWidth
          margin="normal"
          variant="outlined"
          value={profileData?.email || ''}
          disabled
        />
        <TextField
          label="Address"
          fullWidth
          margin="normal"
          variant="outlined"
          value={profileData?.address || ''}
          disabled
        />
        <TextField
          label="Phone Number"
          fullWidth
          margin="normal"
          variant="outlined"
          value={profileData?.phoneNumber || ''}
          disabled
        />
        <TextField
          label="Pin Code"
          fullWidth
          margin="normal"
          variant="outlined"
          value={profileData?.pinCode || ''}
          disabled
        />
        <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 4 }}>
          <Button variant="contained" color="primary" onClick={() => setPasswordDialogOpen(true)}>
            Change Password
          </Button>
          <Button variant="contained" color="primary" onClick={() => setEmailDialogOpen(true)}>
            Change Email
          </Button>
        </Box>
      </Card>

      {/* Change Password Dialog */}
      <Dialog open={passwordDialogOpen} onClose={() => setPasswordDialogOpen(false)}>
        <DialogTitle>Change Password</DialogTitle>
        <DialogContent>
          <TextField
            label="Current Password"
            type="password"
            fullWidth
            margin="dense"
            variant="standard"
            value={currentPassword}
            onChange={(e) => setCurrentPassword(e.target.value)}
          />
          <TextField
            label="New Password"
            type="password"
            fullWidth
            margin="dense"
            variant="standard"
            value={newPassword}
            onChange={(e) => {
              setNewPassword(e.target.value);
              validatePassword(e.target.value);
            }}
          />
          <Typography variant="body2" color={passwordStrength === 'Strong' ? 'green' : passwordStrength === 'Medium' ? 'orange' : 'red'}>
            Password Strength: {passwordStrength}
          </Typography>
          <TextField
            label="Confirm New Password"
            type="password"
            fullWidth
            margin="dense"
            variant="standard"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setPasswordDialogOpen(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={handlePasswordChange} color="primary">
            Confirm
          </Button>
        </DialogActions>
      </Dialog>

      {/* Change Email Dialog */}
      <Dialog open={emailDialogOpen} onClose={() => setEmailDialogOpen(false)}>
        <DialogTitle>Change Email</DialogTitle>
        <DialogContent>
          <TextField
            label="Current Email"
            fullWidth
            margin="dense"
            variant="standard"
            value={profileData?.email || ''}
            disabled
          />
          <TextField
            label="New Email"
            type="email"
            fullWidth
            margin="dense"
            variant="standard"
            value={newEmail}
            onChange={(e) => setNewEmail(e.target.value)}
          />
          <TextField
            label="Confirm New Email"
            type="email"
            fullWidth
            margin="dense"
            variant="standard"
            value={confirmEmail}
            onChange={(e) => setConfirmEmail(e.target.value)}
          />
          <TextField
            label="Current Password"
            type="password"
            fullWidth
            margin="dense"
            variant="standard"
            value={currentPassword}
            onChange={(e) => setCurrentPassword(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setEmailDialogOpen(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={handleEmailChange} color="primary">
            Confirm
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar
        open={snackOpen}
        autoHideDuration={6000}
        onClose={handleSnackClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      >
        <Alert onClose={handleSnackClose} severity={snackSeverity} sx={{ width: '100%' }}>
          {snackMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default Profile;
