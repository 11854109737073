import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
  Snackbar,
  Alert,
} from '@mui/material';
import axios from 'axios';
import { DataGrid } from '@mui/x-data-grid';
import { API_BASE_URL } from '../../constants/constants';
import { jwtDecode } from 'jwt-decode';

const ShopEnquiryAction = () => {
  const [enquiries, setEnquiries] = useState([]);
  const [reviewDialogOpen, setReviewDialogOpen] = useState(false);
  const [selectedEnquiry, setSelectedEnquiry] = useState(null);
  const [reviewNote, setReviewNote] = useState('');
  const [isApproving, setIsApproving] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');
  const [userId, setUserId] = useState(null);
  const [userLevel, setUserLevel] = useState(null);

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      const decodedToken = jwtDecode(token);
      setUserId(decodedToken.userId);
      setUserLevel(decodedToken.level);
      fetchEnquiries(decodedToken.userId);
    }
  }, []);

  const fetchEnquiries = async (userId) => {
    try {
      const response = await axios.get(`${API_BASE_URL}/enquiries/pincodes/${userId}`);
      // Sort the enquiries to bring 'pending' status to the top
      const sortedEnquiries = response.data.sort((a, b) => {
        if (a.status === 'pending' && b.status !== 'pending') return -1;
        if (a.status !== 'pending' && b.status === 'pending') return 1;
        return 0;
      });
      setEnquiries(sortedEnquiries);
    } catch (error) {
      console.error('Error fetching enquiries', error);
      setSnackbarMessage('Error fetching enquiries');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    }
  };

  const handleReviewSubmit = async () => {
    try {
      const reviewPayload = {
        status: isApproving ? 'accepted' : 'rejected',
        note: reviewNote,
      };

      await axios.put(`${API_BASE_URL}/${userId}/enquiries/${selectedEnquiry._id}/review`, reviewPayload);
      
      setSnackbarMessage(`Enquiry ${isApproving ? 'approved' : 'rejected'} successfully`);
      setSnackbarSeverity('success');
      fetchEnquiries(userId);
      handleCloseReviewDialog();
    } catch (error) {
      console.error('Error submitting review:', error);
      setSnackbarMessage(`Error ${isApproving ? 'approving' : 'rejecting'} enquiry`);
      setSnackbarSeverity('error');
    } finally {
      setSnackbarOpen(true);
    }
  };

  const handleOpenReviewDialog = (enquiry, isApproval) => {
    setSelectedEnquiry(enquiry);
    setIsApproving(isApproval);
    setReviewNote('');
    setReviewDialogOpen(true);
  };

  const handleCloseReviewDialog = () => {
    setReviewDialogOpen(false);
    setSelectedEnquiry(null);
  };

  const columns = [
    { field: 'shopName', headerName: 'Shop Name', width: 200 },
    { field: 'address', headerName: 'Address', width: 200 },
    { field: 'pincode', headerName: 'Pincode', width: 150 },
    { field: 'mobileNumber', headerName: 'Mobile Number', width: 150 },
    { 
      field: 'status', 
      headerName: 'Status', 
      width: 150,
      renderCell: (params) => (
        <Typography color={
          params.value === 'accepted' ? 'success.main' : 
          params.value === 'rejected' ? 'error.main' : 
          'text.secondary'
        }>
          {params.value.charAt(0).toUpperCase() + params.value.slice(1)}
        </Typography>
      )
    },
    {
      field: 'actions',
      headerName: 'Actions',
      width: 350,
      renderCell: (params) => (
        <>
          {userLevel === 4 && params.row.status === 'pending' && (
            <>
              <Button
                onClick={() => handleOpenReviewDialog(params.row, true)}
                color="success"
                variant="outlined"
                sx={{ mr: 1 }}
              >
                Approve
              </Button>
              <Button
                onClick={() => handleOpenReviewDialog(params.row, false)}
                color="error"
                variant="outlined"
              >
                Reject
              </Button>
            </>
          )}
        </>
      ),
    },
  ];

  return (
    <Box sx={{ width: '100%', p: 3 }}>
      <Typography variant="h4" gutterBottom>Shop Enquiry Management</Typography>

      <DataGrid
        rows={enquiries}
        columns={columns}
        getRowId={(row) => row._id}
        pageSize={10}
        rowsPerPageOptions={[10, 25, 50]}
        disableSelectionOnClick
        autoHeight
      />

      <Dialog open={reviewDialogOpen} onClose={handleCloseReviewDialog}>
        <DialogTitle>{isApproving ? 'Approve' : 'Reject'} Enquiry</DialogTitle>
        <DialogContent>
          <Typography gutterBottom>
            Please provide a note for why you are {isApproving ? 'approving' : 'rejecting'} this enquiry.
          </Typography>
          <TextField
            label="Note"
            value={reviewNote}
            onChange={(e) => setReviewNote(e.target.value)}
            fullWidth
            multiline
            rows={4}
            sx={{ mt: 2 }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseReviewDialog}>Cancel</Button>
          <Button onClick={handleReviewSubmit} color={isApproving ? 'success' : 'error'} variant="contained">
            {isApproving ? 'Approve' : 'Reject'}
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={() => setSnackbarOpen(false)}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert
          onClose={() => setSnackbarOpen(false)}
          severity={snackbarSeverity}
          sx={{ width: '100%' }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default ShopEnquiryAction;