import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Card, CardContent, Typography, Grid, CardActionArea } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusSquare, faEdit } from '@fortawesome/free-solid-svg-icons';
import {jwtDecode }from 'jwt-decode';
import axios from 'axios';
import { API_BASE_URL } from '../../../constants/constants'; // Assuming you have an API base URL defined in your constants file

// Define the card data as a constant
const cardData = [
  {
    title: 'Create Pincode',
    icon: faPlusSquare,
    path: 'create',
    modules: [1210], // Replace with actual module number for Create Pincode
  },
  {
    title: 'Delete Pincodes',
    icon: faEdit,
    path: 'delete',
    modules: [1211], // Replace with actual module number for Edit Pincodes
  },
];

const PincodeManagement = () => {
  const [userLevel, setUserLevel] = useState(null);
  const [userModules, setUserModules] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    // Decode the JWT and store the user level and userId
    const token = localStorage.getItem('token');
    if (token) {
      const decodedToken = jwtDecode(token);
      setUserLevel(decodedToken.level);

      if (decodedToken.level === 1 || decodedToken.level === 2) {
        // If user is SuperAdmin or Admin, grant access to all modules
        setUserModules(cardData.flatMap(card => card.modules));
      } else {
        // Otherwise, fetch the modules from the privileges stored in the backend
        fetchUserPrivileges(decodedToken.userId);
      }
    }
  }, []);

  const fetchUserPrivileges = async (userId) => {
    try {
      const response = await axios.get(`${API_BASE_URL}/privileges/modules/${userId}`);
      if (response.status === 200 && response.data.modules) {
        setUserModules(response.data.modules);
      } else {
        console.error('Failed to fetch user privileges or no modules found');
      }
    } catch (error) {
      console.error('Error fetching user privileges:', error);
    }
  };

  // Handler for card click
  const handleCardClick = (path) => {
    navigate(path);
  };

  // Function to check if the user has access to a specific module
  const hasAccess = (modules) => {
    return modules.some(moduleId => userModules.includes(moduleId));
  };

  return (
    <Grid container spacing={3} justifyContent="center" alignItems="center">
      {cardData.map((card) => (
        hasAccess(card.modules) && (
          <Grid item xs={12} sm={6} md={4} key={card.title}>
            <Card
              elevation={3}
              sx={{
                maxWidth: 225,
                backgroundColor: 'white',
                '&:hover': {
                  backgroundColor: 'primary.main',
                  color: 'white',
                  '& .MuiTypography-root': {
                    color: 'white !important',
                  },
                  '& .fa-icon': {
                    color: 'white !important',
                  },
                },
              }}
            >
              <CardActionArea onClick={() => handleCardClick(card.path)}>
                <CardContent sx={{ textAlign: 'center' }}>
                  <FontAwesomeIcon
                    icon={card.icon}
                    size="5x"
                    className="fa-icon"
                    style={{ color: 'orange' }}
                  />
                  <Typography gutterBottom variant="h5" component="div" sx={{ marginTop: 2 }}>
                    {card.title}
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>
        )
      ))}
    </Grid>
  );
};

export default PincodeManagement;
