import React, { useContext } from 'react';
import { Typography, Button } from '@mui/material';
import AuthContext from '../context/AuthContext';

const Dashboard = () => {
    const { logout } = useContext(AuthContext);

    return (
        <div>
            <Typography variant="h4">Welcome to the Dashboard</Typography>
            <Button variant="contained" color="primary" onClick={logout}>
                Logout
            </Button>
        </div>
    );
};

export default Dashboard;
