import React, { useState, useEffect, useCallback } from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
  Fab,
  Tooltip,
  Snackbar,
  Alert,
  Autocomplete,
  Chip,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { DataGrid } from '@mui/x-data-grid';
import axios from 'axios';
import { API_BASE_URL } from '../../../constants/constants';
import{ jwtDecode} from 'jwt-decode';
import Loader from '../../General/Loader';
const AssignPrivileges = () => {
  const [users, setUsers] = useState([]);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [modules, setModules] = useState([]);
  const [selectedModule, setSelectedModule] = useState(null);
  const [subModules, setSubModules] = useState([]);
  const [selectedSubModules, setSelectedSubModules] = useState([]);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');
  const [userLevel, setUserLevel] = useState(null);
  const [userId, setUserId] = useState(null);
  const [hasPrivilege, setHasPrivilege] = useState(false);
  const PRIVILEGE_MODULE = 1315;
  const showSnackbar = useCallback((message, severity) => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setSnackbarOpen(true);
  }, []);
  const fetchUsers = useCallback(async (userId) => {
    setLoading(true);
    try {
      const response = await axios.get(`${API_BASE_URL}/users-with-pincodes/${userId}`);
      const usersWithModules = await Promise.all(
        response.data.map(async (user) => {
          try {
            const privileges = await axios.get(`${API_BASE_URL}/privileges/${user.id}`);
            return {
              ...user,
              id: user.id,
              modules: privileges.data.modules || [],
            };
          } catch (error) {
            console.error(`Error fetching privileges for user ${user.id}:`, error);
            return user;
          }
        })
      );
      setUsers(usersWithModules);
    } catch (error) {
      console.error('Error fetching users:', error);
      showSnackbar('Error fetching users', 'error');
    } finally {
      setLoading(false);
    }
  }, [showSnackbar]);
  const fetchAllModules = useCallback(async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/modules`);
      setModules(response.data);
    } catch (error) {
      console.error('Error fetching modules:', error);
      showSnackbar('Error fetching modules', 'error');
    }
  }, [showSnackbar]);
  const fetchSubModules = useCallback(async (moduleId) => {
    try {
      const response = await axios.get(`${API_BASE_URL}/modules-with-submodules/${moduleId}`);
      setSubModules(response.data.subModules || []);
    } catch (error) {
      console.error('Error fetching submodules:', error);
      showSnackbar('Error fetching submodules', 'error');
    }
  }, [showSnackbar]);
  const checkPrivilege = useCallback(async (decodedToken) => {
    if (decodedToken.level <= 2) {
      setHasPrivilege(true);
    } else {
      try {
        const response = await axios.get(`${API_BASE_URL}/privileges/modules/${decodedToken.userId}`);
        setHasPrivilege(response.data.modules.includes(PRIVILEGE_MODULE));
      } catch (error) {
        console.error('Error checking privilege:', error);
        setHasPrivilege(false);
        showSnackbar('You do not have permission to assign privileges', 'error');
      }
    }
  }, [showSnackbar]);
  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      const decodedToken = jwtDecode(token);
      setUserLevel(decodedToken.level);
      setUserId(decodedToken.userId);
      fetchUsers(decodedToken.userId);
      fetchAllModules();
      checkPrivilege(decodedToken);
    }
  }, [fetchUsers, fetchAllModules, checkPrivilege]);
  const handleOpenDialog = () => {
    if (!hasPrivilege) {
      showSnackbar('You do not have permission to assign privileges', 'error');
      return;
    }
    setOpen(true);
  };
  const handleCloseDialog = () => {
    setOpen(false);
    resetDialogState();
  };
  const resetDialogState = () => {
    setSelectedUser(null);
    setSelectedModule(null);
    setSelectedSubModules([]);
    setSubModules([]);
  };
  const handleUserChange = useCallback(async (event, value) => {
    setSelectedUser(value);
    if (value) {
      try {
        const { data } = await axios.get(`${API_BASE_URL}/privileges/${value.id}`);
        const userModules = data?.modules || [];
        const assignedSubModules = userModules.map(module => ({
          moduleId: module.moduleId,
          name: module.name,
          _id: module._id
        }));
        setSelectedSubModules(assignedSubModules);
      } catch (error) {
        console.error('Error fetching user privileges:', error);
        showSnackbar('Error fetching user privileges', 'error');
      }
    } else {
      setSelectedSubModules([]);
      setSelectedModule(null);
    }
  }, [showSnackbar]);
  const handleModuleChange = useCallback(async (event, value) => {
    setSelectedModule(value);
    if (value) {
      await fetchSubModules(value._id);
    } else {
      setSubModules([]);
      setSelectedSubModules([]);
    }
  }, [fetchSubModules]);
  const handleSubModuleChange = (event, newValue) => {
    setSelectedSubModules(newValue);
  };
  const handleSubmit = async () => {
    if (!selectedUser) {
      showSnackbar('Please select a user', 'error');
      return;
    }
    try {
      setLoading(true);
      const { data } = await axios.get(`${API_BASE_URL}/privileges/${selectedUser.id}`);
      const currentModules = data?.modules || [];
      const currentModuleIds = new Set(currentModules.map(m => m.id));
      const selectedModuleIds = new Set(selectedSubModules.map(sm => sm.moduleId).filter(id => id !== null));
      const addModules = [...selectedModuleIds].filter(id => !currentModuleIds.has(id));
      const payload = {};
      if (addModules.length > 0) {
        payload.addModules = addModules;
      }
      if (Object.keys(payload).length > 0) {
        await axios.put(`${API_BASE_URL}/privileges/${selectedUser.id}`, payload);
        showSnackbar('Modules added successfully', 'success');
      } else {
        showSnackbar('No new modules to add', 'info');
      }
      handleCloseDialog();
      await fetchUsers(userId);
    } catch (error) {
      console.error('Error managing privileges:', error);
      showSnackbar('Error managing privileges', 'error');
    } finally {
      setLoading(false);
    }
  };
  const getModuleNames = (modules) => {
    if (!Array.isArray(modules) || modules.length === 0) {
      return 'No modules assigned';
    }
    return modules.map((module) => module.name || `Module ID: ${module.id}`).join(', ');
  };
  const columns = [
    { field: 'fullName', headerName: 'Full Name', width: 200, flex: 1 },
    { field: 'mobile', headerName: 'Mobile', width: 150, flex: 1 },
    {
      field: 'modules',
      headerName: 'Modules',
      width: 400,
      flex: 2,
      renderCell: (params) => {
        try {
          return (
            <div style={{ whiteSpace: 'normal', wordWrap: 'break-word', wordBreak: 'break-word', lineHeight: '1.2' }}>
              {getModuleNames(params.value)}
            </div>
          );
        } catch (error) {
          console.error('Error rendering modules:', error, params.value);
          return 'Error displaying modules';
        }
      },
    },
  ];
  return (
    <Box sx={{ width: '100%', height: 'auto', position: 'relative', mt: 4 }}>
      <Typography variant="h4" gutterBottom>
        Assign Privileges
      </Typography>
      {loading ? (
        <Loader />
      ) : (
        <>
          <Box sx={{ height: 500, width: '100%' }}>
            <DataGrid 
              rows={users} 
              columns={columns} 
              pageSize={20} 
              getRowId={(row) => row.id}
              disableSelectionOnClick
              sx={{
                '& .MuiDataGrid-cell': {
                  whiteSpace: 'normal !important',
                  wordWrap: 'break-word !important',
                  lineHeight: '2.5 !important',
                  height: 'auto !important',
                  alignItems: 'flex-start !important',
                },
                '& .MuiDataGrid-row': {
                  maxHeight: 'none !important',
                  height: 'auto !important',
                },
              }}
            />
          </Box>
          <Tooltip title="Assign Privileges">
            <Fab
              color="primary"
              aria-label="add"
              sx={{ position: 'absolute', top: -28, right: 16 }}
              onClick={handleOpenDialog}
            >
              <AddIcon />
            </Fab>
          </Tooltip>
        </>
      )}
      <Dialog open={open} onClose={handleCloseDialog} fullWidth maxWidth="sm">
        <DialogTitle>Assign Privileges</DialogTitle>
        <DialogContent>
          <Autocomplete
            options={users}
            getOptionLabel={(option) => option.fullName}
            value={selectedUser}
            onChange={handleUserChange}
            renderInput={(params) => <TextField {...params} label="Select User" variant="outlined" />}
            sx={{ mb: 2 }}
          />
          <Autocomplete
            options={modules}
            getOptionLabel={(option) => option.moduleName}
            value={selectedModule}
            onChange={handleModuleChange}
            renderInput={(params) => <TextField {...params} label="Select Module" variant="outlined" />}
            sx={{ mb: 2 }}
          />
          <Autocomplete
            multiple
            options={subModules}
            getOptionLabel={(option) => option.name}
            value={selectedSubModules}
            onChange={handleSubModuleChange}
            isOptionEqualToValue={(option, value) => option.name === value.name}
            renderInput={(params) => <TextField {...params} label="Select SubModules" variant="outlined" />}
            renderTags={(value, getTagProps) =>
              value.map((option, index) => (
                <Chip
                  key={option._id || index}
                  label={option.name}
                  {...getTagProps({ index })}
                  onDelete={null} // Disable the delete button by setting it to null
                />
              ))
            }
            sx={{ mb: 2 }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="secondary">
            Cancel
          </Button>
          <Button onClick={handleSubmit} color="primary" variant="contained">
            Submit
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={() => setSnackbarOpen(false)}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      >
        <Alert
          onClose={() => setSnackbarOpen(false)}
          severity={snackbarSeverity}
          sx={{ width: '100%' }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
};
export default AssignPrivileges;