import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
  Fab,
  Tooltip,
  Snackbar,
  Alert,
  Grid,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { DataGrid } from '@mui/x-data-grid';
import axios from 'axios';
import { API_BASE_URL } from '../../../constants/constants';
import {jwtDecode} from 'jwt-decode';

const CreateAdmin = () => {
  const [users, setUsers] = useState([]);
  const [open, setOpen] = useState(false);
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [address, setAddress] = useState('');
  const [level, setLevel] = useState('');
  const [pinCode, setPinCode] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [email, setEmail] = useState('');
  const [filterStatus, setFilterStatus] = useState('');
  const [filterLevel, setFilterLevel] = useState('');
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('error'); // Track severity for Snackbar
  const [userLevel, setUserLevel] = useState(null);
  const [userId, setUserId] = useState(null);
  const [validationErrors, setValidationErrors] = useState({});

  const levelNames = {
    2: 'Admin',
    3: 'MSB_HO',
    4: 'MSB',
    5: 'MSB Staff',
  };

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      const decodedToken = jwtDecode(token);
      setUserLevel(decodedToken.level);
      setUserId(decodedToken.userId);
      setLevel(decodedToken.level + 1); // Automatically set the next level
    }
  }, []);

  useEffect(() => {
    if (userId) {
      fetchUsers();
    }
  }, [userId, filterStatus, filterLevel]);
  

  const fetchUsers = async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/users/all/${userId}`, {
        params: {
          status: filterStatus,
          level: filterLevel,
        },
      });
      setUsers(response.data);
    } catch (error) {
      console.error('Error fetching users:', error);
    }
  };

  const handleFilterStatusChange = (event) => {
    setFilterStatus(event.target.value);
  };

  const handleFilterLevelChange = (event) => {
    setFilterLevel(event.target.value);
  };

  const handleOpenDialog = () => {
    setOpen(true);
  };

  const handleCloseDialog = () => {
    if (firstName || lastName || address || level || pinCode || phoneNumber || email) {
      setConfirmDialogOpen(true);
    } else {
      clearDialogData();
    }
  };

  const handleConfirmDialogClose = () => {
    setConfirmDialogOpen(false);
  };

  const handleConfirmDialogProceed = () => {
    setConfirmDialogOpen(false);
    clearDialogData();
  };

  const clearDialogData = () => {
    setOpen(false);
    setFirstName('');
    setLastName('');
    setAddress('');
    setLevel(userLevel + 1); // Reset to the next level based on user's level
    setPinCode('');
    setPhoneNumber('');
    setEmail('');
    setSnackbarMessage('');
    setValidationErrors({});
  };

  const validateForm = () => {
    const errors = {};
    if (!/^\d{10}$/.test(phoneNumber)) {
      errors.phoneNumber = 'Phone number must be 10 digits.';
    }
    if (!/^\d{6}$/.test(pinCode)) {
      errors.pinCode = 'Pin code must be a 6-digit number.';
    }
    if (!/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/.test(email)) {
      errors.email = 'Enter a valid email address.';
    }
    return errors;
  };

  const handleAddUser = async () => {
    const errors = validateForm();
    if (Object.keys(errors).length > 0) {
      setValidationErrors(errors);
      setSnackbarMessage('Please fix the errors in the form.');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
      return;
    }

    try {
      await axios.post(`${API_BASE_URL}/users/create`, {
        firstName,
        lastName,
        address,
        level,
        pinCode,
        phoneNumber,
        email,
        createdBy: userId,
        status: 'active',
      });

      setSnackbarMessage('User created successfully');
      setSnackbarSeverity('success'); // Set Snackbar to show success message in green
      setSnackbarOpen(true);
      clearDialogData();
      fetchUsers(); // Refetch the user list
    } catch (error) {
      setSnackbarMessage(error.response?.data?.message || 'Error creating user');
      setSnackbarSeverity('error'); // Set Snackbar to show error message in red
      setSnackbarOpen(true);
      console.error('Error creating user:', error);
    }
  };

  const handleStatusToggle = async (id, currentStatus) => {
    try {
      const newStatus = currentStatus === 'active' ? 'inactive' : 'active';
      await axios.patch(`${API_BASE_URL}/users/update-status/${id}`, {
        status: newStatus,
        requesterId: userId, // Include the requesterId in the request body
      });
      
      setSnackbarMessage(`User ${newStatus === 'active' ? 'activated' : 'deactivated'} successfully`);
      setSnackbarSeverity('success'); // Show success message in green
      setSnackbarOpen(true);
      
      fetchUsers(); // Refetch the user list after updating the status
    } catch (error) {
      setSnackbarMessage(error.response?.data?.message || 'Error updating user status');
      setSnackbarSeverity('error'); // Show error message in red
      setSnackbarOpen(true);
      console.error('Error updating status:', error);
    }
  };
  
  const columns = [
    { field: 'firstName', headerName: 'First Name', width: 150 },
    { field: 'lastName', headerName: 'Last Name', width: 150 },
    { field: 'email', headerName: 'Email', width: 200 },
    { field: 'phoneNumber', headerName: 'Phone Number', width: 150 },
    { field: 'level', headerName: 'Level', width: 100 },
    {
      field: 'status',
      headerName: 'Status',
      width: 200,
      renderCell: (params) => (
        <Button
          variant="contained"
          color={params.value === 'active' ? 'secondary' : 'primary'}
          onClick={() => handleStatusToggle(params.row.id, params.value)}
        >
          {params.value === 'active' ? 'Deactivate' : 'Activate'}
        </Button>
      ),
    },
  ];
  

  const rows = users.map((user) => ({
    id: user._id,
    firstName: user.firstName,
    lastName: user.lastName,
    email: user.email,
    phoneNumber: user.phoneNumber,
    level: levelNames[user.level] || user.level, // Convert level to name if available
    status: user.status,
  }));

  return (
    <Box sx={{ height: 400, width: '100%', position: 'relative' }}>
      <Typography variant="h4" gutterBottom>
        Admin Management
      </Typography>
      <Grid container spacing={2} sx={{ mb: 2 }}>
        <Grid item xs={6}>
          <FormControl fullWidth>
            <InputLabel>Status</InputLabel>
            <Select value={filterStatus} onChange={handleFilterStatusChange}>
              <MenuItem value="">All</MenuItem>
              <MenuItem value="active">Active</MenuItem>
              <MenuItem value="inactive">Inactive</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={6}>
          <FormControl fullWidth>
            <InputLabel>Admin Type</InputLabel>
            <Select value={filterLevel} onChange={handleFilterLevelChange}>
              {userLevel <= 2 && <MenuItem value="2">Admin</MenuItem>}
              {userLevel <= 3 && <MenuItem value="3">MSB_HO</MenuItem>}
              {userLevel <= 4 && <MenuItem value="4">MSB</MenuItem>}
              {userLevel <= 5 && <MenuItem value="5">MSB Staff</MenuItem>}
            </Select>
          </FormControl>
        </Grid>
      </Grid>
      <DataGrid rows={rows} columns={columns} pageSize={5} />
      <Tooltip title="Create New Admin">
        <Fab
          color="primary"
          aria-label="add"
          sx={{ position: 'absolute', top: -7, right: 16 }}
          onClick={handleOpenDialog}
        >
          <AddIcon />
        </Fab>
      </Tooltip>

      <Dialog open={open} onClose={handleCloseDialog} fullWidth maxWidth="sm">
        <DialogTitle>Create New Admin</DialogTitle>
        <DialogContent>
          <TextField
            label="First Name"
            variant="outlined"
            fullWidth
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
            sx={{ mb: 2 }}
          />
          <TextField
            label="Last Name"
            variant="outlined"
            fullWidth
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
            sx={{ mb: 2 }}
          />
          <TextField
            label="Address"
            variant="outlined"
            fullWidth
            value={address}
            onChange={(e) => setAddress(e.target.value)}
            sx={{ mb: 2 }}
          />
          <TextField
            label="Admin Type"
            variant="outlined"
            fullWidth
            value={levelNames[level] || level} // Automatically show associated name
            disabled
            sx={{ mb: 2 }}
          />
          <TextField
            label="Pin Code"
            variant="outlined"
            fullWidth
            value={pinCode}
            onChange={(e) => setPinCode(e.target.value)}
            sx={{ mb: 2 }}
            error={!!validationErrors.pinCode}
            helperText={validationErrors.pinCode}
          />
          <TextField
            label="Phone Number"
            variant="outlined"
            fullWidth
            value={phoneNumber}
            onChange={(e) => setPhoneNumber(e.target.value)}
            sx={{ mb: 2 }}
            error={!!validationErrors.phoneNumber}
            helperText={validationErrors.phoneNumber}
          />
          <TextField
            label="Email"
            variant="outlined"
            fullWidth
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            sx={{ mb: 2 }}
            error={!!validationErrors.email}
            helperText={validationErrors.email}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="secondary">
            Cancel
          </Button>
          <Button onClick={handleAddUser} color="primary" variant="contained">
            Submit
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar
  open={snackbarOpen}
  autoHideDuration={3000}
  onClose={() => setSnackbarOpen(false)}
  anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
>
  <Alert
    onClose={() => setSnackbarOpen(false)}
    severity={snackbarSeverity} // Show success or error based on severity
    sx={{ width: '100%' }}
  >
    {snackbarMessage}
  </Alert>
</Snackbar>


      <Dialog open={confirmDialogOpen} onClose={handleConfirmDialogClose}>
        <DialogTitle>Unsaved Changes</DialogTitle>
        <DialogContent>
          <Typography>You have unsaved changes. Do you really want to close?</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleConfirmDialogClose} color="secondary">
            Cancel
          </Button>
          <Button onClick={handleConfirmDialogProceed} color="primary" variant="contained">
            Proceed
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default CreateAdmin;
