import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  Snackbar,
  Alert,
  CircularProgress,
  FormControl,
  TextField,
  Grid,
  Paper,
  Chip,
  Tabs,
  Tab,
} from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import axios from 'axios';
import { API_BASE_URL } from '../../constants/constants';
import { jwtDecode } from 'jwt-decode';

const SetShopCommission = () => {
  const [shops, setShops] = useState([]);
  const [loading, setLoading] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [detailDialogOpen, setDetailDialogOpen] = useState(false);
  const [selectedShop, setSelectedShop] = useState(null);
  const [commissionRate, setCommissionRate] = useState('');
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');
  const [userId, setUserId] = useState(null);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(5);
  const [totalRows, setTotalRows] = useState(0);
  const [activeTab, setActiveTab] = useState(0);

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      try {
        const decodedToken = jwtDecode(token);
        setUserId(decodedToken.userId);
      } catch (error) {
        console.error('Error decoding token:', error);
        setSnackbarMessage('Error decoding user token');
        setSnackbarSeverity('error');
        setSnackbarOpen(true);
      }
    } else {
      console.error('No token found in localStorage');
      setSnackbarMessage('No user token found');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    }
  }, []);

  useEffect(() => {
    if (userId) {
      fetchShops();
    }
  }, [userId, page, pageSize]);

  const fetchShops = async () => {
    if (!userId) return;
    setLoading(true);
    try {
      const response = await axios.get(`${API_BASE_URL}/shops/${userId}`, {
        params: {
          progress: "2",
          verificationStatus: "verified",
          page: page + 1,
          limit: pageSize
        }
      });
      setShops(response.data.shops);
      setTotalRows(response.data.totalShops);
    } catch (error) {
      console.error('Error fetching shops:', error);
      setSnackbarMessage('Error fetching shops');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    } finally {
      setLoading(false);
    }
  };

  const handleDialogOpen = (shop) => {
    setSelectedShop(shop);
    setDialogOpen(true);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
    setSelectedShop(null);
    setCommissionRate('');
  };

  const handleDetailDialogOpen = (shop) => {
    setSelectedShop(shop);
    setDetailDialogOpen(true);
  };

  const handleDetailDialogClose = () => {
    setDetailDialogOpen(false);
    setSelectedShop(null);
    setActiveTab(0);
  };

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const handleCommissionChange = (e) => {
    const value = e.target.value;
    if (value === '' || (value >= 0 && value <= 100)) {
      setCommissionRate(value);
    }
  };

  const handleSetCommission = async () => {
    if (!commissionRate || !selectedShop) {
      setSnackbarMessage('Please enter a valid commission rate');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
      return;
    }

    try {
      await axios.put(`${API_BASE_URL}/shops/${userId}/${selectedShop._id}`, {
        commissionRate: parseFloat(commissionRate),
        progress: '3'
      });

      setSnackbarMessage('Commission set and shop progress updated successfully');
      setSnackbarSeverity('success');
      setSnackbarOpen(true);
      fetchShops();
      handleDialogClose();
    } catch (error) {
      console.error('Error setting commission:', error);
      setSnackbarMessage('Error setting commission or updating shop progress');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    }
  };

  const renderDocument = (document, documentName) => {
    if (!document) {
      return <Typography>No {documentName} available</Typography>;
    }

    let src = document;
    if (!document.startsWith('data:')) {
      src = `data:application/pdf;base64,${document}`;
    }

    if (src.includes('application/pdf')) {
      return (
        <object
          data={src}
          type="application/pdf"
          width="100%"
          height="500px"
        >
          <Typography>
            Your browser does not support PDFs.
            <a href={src} target="_blank" rel="noopener noreferrer">Download the PDF</a>.
          </Typography>
        </object>
      );
    } else {
      return <img src={src} alt={documentName} style={{ maxWidth: '100%', maxHeight: '500px' }} />;
    }
  };

  const columns = [
    { field: 'shopName', headerName: 'Shop Name', width: 200 },
    { field: 'shopType', headerName: 'Shop Type', width: 150 },
    { field: 'shopPincode', headerName: 'Pincode', width: 150 },
    { field: 'promoterName', headerName: 'Promoter Name', width: 200 },
    { field: 'promoterContact', headerName: 'Promoter Contact', width: 150 },
    { 
      field: 'verificationStatus', 
      headerName: 'Verification', 
      width: 150,
      renderCell: (params) => (
        <Chip label={params.value} color={params.value === 'verified' ? 'success' : 'default'} />
      )
    },
    {
      field: 'actions',
      headerName: 'Actions',
      width: 400,
      renderCell: (params) => (
        <>
          <Button variant="outlined" color="primary" onClick={() => handleDetailDialogOpen(params.row)} sx={{ mr: 1 }}>
            Know More
          </Button>
          <Button variant="outlined" color="secondary" onClick={() => handleDialogOpen(params.row)}>
            Set Commission
          </Button>
        </>
      ),
    },
  ];

  return (
    <Box sx={{ width: '100%', p: 3 }}>
      <Typography variant="h4" gutterBottom>
        Set Shop Commission
      </Typography>

      <Box sx={{ height: 400, width: '100%' }}>
        <DataGrid
          rows={shops}
          columns={columns}
          pageSize={pageSize}
          rowsPerPageOptions={[5, 10, 20]}
          pagination
          page={page}
          rowCount={totalRows}
          paginationMode="server"
          onPageChange={(newPage) => setPage(newPage)}
          onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
          getRowId={(row) => row._id}
          disableSelectionOnClick
          loading={loading}
          components={{
            NoRowsOverlay: () => (
              <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                <Typography>No shops found matching the criteria</Typography>
              </Box>
            ),
            LoadingOverlay: () => (
              <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                <CircularProgress />
              </Box>
            ),
          }}
        />
      </Box>

      {/* Commission Setting Dialog */}
      <Dialog open={dialogOpen} onClose={handleDialogClose} fullWidth maxWidth="sm">
        <DialogTitle>
          <Typography variant="h5" color="primary">
            Set Commission for {selectedShop?.shopName}
          </Typography>
        </DialogTitle>
        <DialogContent>
          <TextField
            label="Commission Rate (%)"
            type="number"
            value={commissionRate}
            onChange={handleCommissionChange}
            fullWidth
            inputProps={{ min: 0, max: 100 }}
            sx={{ mt: 2 }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose} color="secondary">
            Cancel
          </Button>
          <Button onClick={handleSetCommission} color="primary" variant="contained">
            Set Commission
          </Button>
        </DialogActions>
      </Dialog>

      {/* Detail Dialog */}
      <Dialog open={detailDialogOpen} onClose={handleDetailDialogClose} fullWidth maxWidth="md">
        <DialogTitle>
          <Typography variant="h4" color="primary">
            {selectedShop?.shopName} Details
          </Typography>
        </DialogTitle>
        <DialogContent>
          {selectedShop && (
            <Box sx={{ width: '100%' }}>
              <Tabs value={activeTab} onChange={handleTabChange} centered>
                <Tab label="Shop Details" />
                <Tab label="Promoter Details" />
                <Tab label="Documents" />
              </Tabs>
              <Box sx={{ mt: 2 }}>
                {activeTab === 0 && (
                  <Paper elevation={3} sx={{ p: 2 }}>
                    <Typography><strong>Address:</strong> {selectedShop.shopAddress}</Typography>
                    <Typography><strong>Type:</strong> {selectedShop.shopType}</Typography>
                    <Typography><strong>Pincode:</strong> {selectedShop.shopPincode}</Typography>
                    <Typography><strong>License Number:</strong> {selectedShop.shopLicanceNumber || 'N/A'}</Typography>
                    <Typography><strong>GST Number:</strong> {selectedShop.shopGstNumber || 'N/A'}</Typography>
                    <Typography><strong>Email:</strong> {selectedShop.shopEmail || 'N/A'}</Typography>
                    <Typography><strong>Account Number:</strong> {selectedShop.shopAccountNumber}</Typography>
                    <Typography><strong>Bank:</strong> {selectedShop.shopBank}</Typography>
                    <Typography><strong>Branch:</strong> {selectedShop.shopBranch}</Typography>
                    <Typography><strong>IFSC:</strong> {selectedShop.shopIFSC}</Typography>
                  </Paper>
                )}
                {activeTab === 1 && (
                  <Paper elevation={3} sx={{ p: 2 }}>
                    <Typography><strong>Name:</strong> {selectedShop.promoterName}</Typography>
                    <Typography><strong>Contact:</strong> {selectedShop.promoterContact}</Typography>
                    <Typography><strong>Aadhar:</strong> {selectedShop.promoterAdhaar || 'N/A'}</Typography>
                    <Typography><strong>PAN:</strong> {selectedShop.promoterPan || 'N/A'}</Typography>
                    <Typography><strong>WhatsApp:</strong> {selectedShop.promoterWhatsApp || 'N/A'}</Typography>
                    <Typography><strong>Email:</strong> {selectedShop.promoterEmail || 'N/A'}</Typography>
                  </Paper>
                )}
                {activeTab === 2 && (
                  <Paper elevation={3} sx={{ p: 2 }}>
                    <Typography variant="h6" gutterBottom>Shop License</Typography>
                    {renderDocument(selectedShop.shopLicance, 'Shop License')}
                    <Typography variant="h6" gutterBottom sx={{ mt: 2 }}>GST Certificate</Typography>
                    {renderDocument(selectedShop.shopGstCertificate, 'GST Certificate')}
                    <Typography variant="h6" gutterBottom sx={{ mt: 2 }}>Aadhar</Typography>
                    {renderDocument(selectedShop.adhaar, 'Aadhar')}
                    <Typography variant="h6" gutterBottom sx={{ mt: 2 }}>PAN</Typography>
                    {renderDocument(selectedShop.pan, 'PAN')}
                    <Typography variant="h6" gutterBottom sx={{ mt: 2 }}>Bank Proof</Typography>
                    {renderDocument(selectedShop.bankProof, 'Bank Proof')}
                    <Typography variant="h6" gutterBottom sx={{ mt: 2 }}>Shop Photo</Typography>
                    {renderDocument(selectedShop.shopPhoto, 'Shop Photo')}
                  </Paper>
                )}
              </Box>
            </Box>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDetailDialogClose} color="secondary">
            Close
          </Button>
        </DialogActions>
      </Dialog>

      {/* Snackbar for notifications */}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={() => setSnackbarOpen(false)}
      >
        <Alert
          onClose={() => setSnackbarOpen(false)}
          severity={snackbarSeverity}
          sx={{ width: '100%' }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default SetShopCommission;