import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Card, CardContent, Typography, Grid, CardActionArea } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserPlus, faUserShield ,faPlusSquare, faUserMinus} from '@fortawesome/free-solid-svg-icons';
import { jwtDecode } from 'jwt-decode';
import axios from 'axios';
import { API_BASE_URL } from '../../../constants/constants';

const cardData = [
  {
    title: 'Create Admin',
    icon: faUserPlus,
    path: '/adminControl/admins/create',
    levels: [1, 2, 3, 4],
  },
  {
    title: 'Assign Privileges',
    icon: faUserShield,
    path: '/adminControl/admins/assign-privileges',
    levels: [1, 2, 3, 4],
  },
  {
    title: 'Remove Privilage',
    icon: faUserMinus,
    path: '/adminControl/admins/remove-privilages',
    levels: [1, 2, 3, 4],
  },
  {
    title: 'Assign Pincodes',
    icon: faPlusSquare,
    path: '/adminControl/admins/assign-pincodes',
    levels: [1, 2, 3, 4],
  },


  // Add more cards as needed with appropriate levels
];

const AdminManagement = () => {
  const [userLevel, setUserLevel] = useState(null);
  const [userModules, setUserModules] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      const decodedToken = jwtDecode(token);
      setUserLevel(decodedToken.level);

      // Fetch modules if needed
      verifyUserModules(decodedToken);
    }
  }, []);

  const verifyUserModules = async (decodedToken) => {
    if (decodedToken.level === 1 || decodedToken.level === 2 || decodedToken.level === 3 || decodedToken.level === 4) {
      setUserModules(cardData.flatMap(card => card.levels));
    } else {
      try {
        const response = await axios.get(`${API_BASE_URL}/privileges/modules/${decodedToken.userId}`);
        setUserModules(response.data.modules || []);
      } catch (error) {
        console.error('Error fetching user privileges:', error);
      }
    }
  };

  const hasAccess = (levels) => {
    return levels.includes(userLevel);
  };

  const handleCardClick = (path) => {
    navigate(path);
  };

  return (
    <Grid container spacing={3} justifyContent="center" alignItems="center">
      {cardData.map((card) => (
        hasAccess(card.levels) && (
          <Grid item xs={12} sm={6} md={3} key={card.title}>
            <Card
              elevation={3}
              sx={{
                maxWidth: 225,
                backgroundColor: 'white',
                '&:hover': {
                  backgroundColor: 'primary.main',
                  color: 'white',
                  '& .MuiTypography-root': {
                    color: 'white !important',
                  },
                  '& .fa-icon': {
                    color: 'white !important',
                  },
                },
              }}
            >
              <CardActionArea onClick={() => handleCardClick(card.path)}>
                <CardContent sx={{ textAlign: 'center' }}>
                  <FontAwesomeIcon
                    icon={card.icon}
                    size="5x"
                    className="fa-icon"
                    style={{ color: 'orange' }}
                  />
                  <Typography gutterBottom variant="h5" component="div" sx={{ marginTop: 2 }}>
                    {card.title}
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>
        )
      ))}
    </Grid>
  );
};

export default AdminManagement;
