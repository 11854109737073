import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Card, CardContent, Typography, Grid, CardActionArea } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShop, faStore, faShopLock, faHandshake, faQrcode, faBarcode } from '@fortawesome/free-solid-svg-icons';
import { jwtDecode } from 'jwt-decode';
import axios from 'axios';
import { API_BASE_URL } from '../../constants/constants';

const cardData = [
  {
    title: 'Create Shop',
    icon: faShop,
    path: '/shop/create',
    levels: [4],
    moduleCode: null,
  },
  {
    title: 'All Shops',
    icon: faStore,
    path: '/shop/all-shops',
    levels: [1, 2, 3, 4],
    moduleCode: null,
  },
  {
    title: 'Verify Documents',
    icon: faShopLock,
    path: '/shop/verify-documents',
    levels: [],
    moduleCode: 1410,
  },
  {
    title: 'Set Commission',
    icon: faHandshake,
    path: '/shop/set-commission',
    levels: [1, 2],
    moduleCode: null,
  },
  {
    title: 'QR Generator',
    icon: faQrcode,
    path: '/shop/generate-qr-code',
    levels: [],
    moduleCode: 1411,
  },
  {
    title: 'Provide QR Code',
    icon: faBarcode,
    path: '/shop/provide-qr-code',
    levels: [],
    moduleCode: 1412,
  },
];

const ShopManagement = () => {
  const [userLevel, setUserLevel] = useState(null);
  const [userModules, setUserModules] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      const decodedToken = jwtDecode(token);
      setUserLevel(decodedToken.level);
      verifyUserModules(decodedToken);
    }
  }, []);

  const verifyUserModules = async (decodedToken) => {
    try {
      const response = await axios.get(`${API_BASE_URL}/privileges/modules/${decodedToken.userId}`);
      setUserModules(response.data.modules || []);
    } catch (error) {
      console.error('Error fetching user privileges:', error);
    }
  };

  const hasAccess = (card) => {
    if (card.levels.includes(userLevel)) {
      return true;
    }
    if (card.moduleCode && userModules.includes(card.moduleCode)) {
      return true;
    }
    return false;
  };

  const handleCardClick = (path) => {
    navigate(path);
  };

  return (
    <Grid container spacing={3}  alignItems="center">
      {cardData.map((card) => (
        hasAccess(card) && (
          <Grid item xs={12} sm={6} md={3} key={card.title}>
            <Card
              elevation={3}
              sx={{
                maxWidth: 225,
                backgroundColor: 'white',
                '&:hover': {
                  backgroundColor: 'primary.main',
                  color: 'white',
                  '& .MuiTypography-root': {
                    color: 'white !important',
                  },
                  '& .fa-icon': {
                    color: 'white !important',
                  },
                },
              }}
            >
              <CardActionArea onClick={() => handleCardClick(card.path)}>
                <CardContent sx={{ textAlign: 'center' }}>
                  <FontAwesomeIcon
                    icon={card.icon}
                    size="5x"
                    className="fa-icon"
                    style={{ color: 'orange' }}
                  />
                  <Typography gutterBottom variant="h5" component="div" sx={{ marginTop: 2 }}>
                    {card.title}
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>
        )
      ))}
    </Grid>
  );
};

export default ShopManagement;