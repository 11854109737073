import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  Snackbar,
  Alert,
  CircularProgress,
  Grid,
  Paper,
  Chip,
  TextField,
} from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import axios from 'axios';
import { API_BASE_URL } from '../../constants/constants';
import { jwtDecode } from 'jwt-decode';
import { useNavigate } from 'react-router-dom';

const VerifyDocuments = () => {
  const PRIVILEGE_MODULE = 1410;
  const [shops, setShops] = useState([]);
  const [loading, setLoading] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [documentDialogOpen, setDocumentDialogOpen] = useState(false);
  const [selectedShop, setSelectedShop] = useState(null);
  const [selectedDocument, setSelectedDocument] = useState(null);
  const [selectedDocumentType, setSelectedDocumentType] = useState('');
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');
  const [userId, setUserId] = useState(null);
  const [hasPrivilege, setHasPrivilege] = useState(false);
  const [remark, setRemark] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      const decodedToken = jwtDecode(token);
      setUserId(decodedToken.userId);
      checkPrivilege(decodedToken.userId);
    }
  }, []);

  const checkPrivilege = async (userId) => {
    try {
      const response = await axios.get(`${API_BASE_URL}/privileges/modules/${userId}`);
      setHasPrivilege(response.data.modules.includes(PRIVILEGE_MODULE));
      if (response.data.modules.includes(PRIVILEGE_MODULE)) {
        fetchShops(userId);
      }
    } catch (error) {
      console.error('Error checking privilege', error);
      setSnackbarMessage('You do not have permission to verify documents');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    }
  };

  const fetchShops = async (userId) => {
    setLoading(true);
    try {
      const response = await axios.get(`${API_BASE_URL}/shops/${userId}`, {
        params: {
          progress: '1',
          verificationStatus: 'notVerified',
        },
      });
      setShops(response.data.shops);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setSnackbarMessage('Error fetching shops');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    }
  };

  const handleDialogOpen = (shop) => {
    setSelectedShop(shop);
    setDialogOpen(true);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
    setSelectedShop(null);
    setRemark('');
  };

  const handleDocumentDialogOpen = (documentType) => {
    setSelectedDocumentType(documentType);
    setSelectedDocument(selectedShop[documentType]);
    setDocumentDialogOpen(true);
  };

  const handleDocumentDialogClose = () => {
    setDocumentDialogOpen(false);
    setSelectedDocument(null);
    setSelectedDocumentType('');
  };
  const renderDocument = (document) => {
    if (!document) {
      return <Typography>No document available</Typography>;
    }

    let src = document;
    if (!document.startsWith('data:')) {
      // If it's just a base64 string without the data URL prefix, add it
      src = `data:application/pdf;base64,${document}`;
    }

    if (src.includes('application/pdf')) {
      return (
        <object
          data={src}
          type="application/pdf"
          width="100%"
          height="600px"
        >
          <Typography>
            Your browser does not support PDFs.
            <a href={src} target="_blank" rel="noopener noreferrer">Download the PDF</a>.
          </Typography>
        </object>
      );
    } else {
      return <img src={src} alt="Document" style={{ width: '100%' }} />;
    }
  };

  const handleActivate = async () => {
    if (!selectedShop) return;

    try {
      const payload = {
        progress: '2', // Move to next stage
        verificationStatus: 'verified',
        remark,
      };
      await axios.put(`${API_BASE_URL}/shops/${userId}/${selectedShop._id}`, payload);
      setSnackbarMessage('Shop activated successfully');
      setSnackbarSeverity('success');
      fetchShops(userId);
      handleDialogClose();
    } catch (error) {
      setSnackbarMessage('Error activating shop');
      setSnackbarSeverity('error');
    } finally {
      setSnackbarOpen(true);
    }
  };

  const handleReject = async () => {
    if (!selectedShop) return;

    try {
      const payload = {
        verificationStatus: 'rejected',
        remark,
      };
      await axios.put(`${API_BASE_URL}/shops/${userId}/${selectedShop._id}`, payload);
      setSnackbarMessage('Shop rejected successfully');
      setSnackbarSeverity('success');
      fetchShops(userId);
      handleDialogClose();
    } catch (error) {
      setSnackbarMessage('Error rejecting shop');
      setSnackbarSeverity('error');
    } finally {
      setSnackbarOpen(true);
    }
  };

  const columns = [
    { field: 'shopName', headerName: 'Shop Name', width: 200 },
    { field: 'shopType', headerName: 'Shop Type', width: 150 },
    { field: 'shopPincode', headerName: 'Pincode', width: 150 },
    { field: 'promoterName', headerName: 'Promoter Name', width: 200 },
    { field: 'promoterContact', headerName: 'Promoter Contact', width: 150 },
    {
      field: 'actions',
      headerName: 'Actions',
      width: 200,
      renderCell: (params) => (
        <Button variant="outlined" color="primary" onClick={() => handleDialogOpen(params.row)}>
          Know More
        </Button>
      ),
    },
  ];

  return (
    <Box sx={{ width: '100%', p: 3 }}>
      <Typography variant="h4" gutterBottom>
        Verify Shop Documents
      </Typography>

      {loading ? (
        <CircularProgress />
      ) : (
        <Box sx={{ height: 400, width: '100%' }}>
          <DataGrid
            rows={shops}
            columns={columns}
            pageSize={5}
            rowsPerPageOptions={[5, 10, 20]}
            getRowId={(row) => row._id}
            disableSelectionOnClick
          />
        </Box>
      )}

      {/* Dialog for showing all shop details including documents */}
      <Dialog open={dialogOpen} onClose={handleDialogClose} fullWidth maxWidth="md">
        <DialogTitle>
          <Typography variant="h4" color="primary">
            {selectedShop?.shopName}
          </Typography>
        </DialogTitle>
        <DialogContent>
          {selectedShop && (
            <Grid container spacing={3}>
              <Grid item xs={12} md={6}>
                <Paper elevation={3} sx={{ p: 2, height: '100%' }}>
                  <Typography variant="h6" color="secondary" gutterBottom>Shop Details</Typography>
                  <Typography><strong>Address:</strong> {selectedShop.shopAddress}</Typography>
                  <Typography><strong>Type:</strong> {selectedShop.shopType}</Typography>
                  <Typography><strong>Pincode:</strong> {selectedShop.shopPincode}</Typography>
                  <Typography><strong>License Number:</strong> {selectedShop.shopLicanceNumber || 'N/A'}</Typography>
                  <Typography><strong>GST Number:</strong> {selectedShop.shopGstNumber || 'N/A'}</Typography>
                  <Typography><strong>Email:</strong> {selectedShop.shopEmail || 'N/A'}</Typography>
                </Paper>
              </Grid>
              <Grid item xs={12} md={6}>
                <Paper elevation={3} sx={{ p: 2, height: '100%' }}>
                  <Typography variant="h6" color="secondary" gutterBottom>Promoter Details</Typography>
                  <Typography><strong>Name:</strong> {selectedShop.promoterName}</Typography>
                  <Typography><strong>Contact:</strong> {selectedShop.promoterContact}</Typography>
                  <Typography><strong>Aadhar:</strong> {selectedShop.promoterAdhaar || 'N/A'}</Typography>
                  <Typography><strong>PAN:</strong> {selectedShop.promoterPan || 'N/A'}</Typography>
                  <Typography><strong>WhatsApp:</strong> {selectedShop.promoterWhatsApp || 'N/A'}</Typography>
                  <Typography><strong>Email:</strong> {selectedShop.promoterEmail || 'N/A'}</Typography>
                </Paper>
              </Grid>
              <Grid item xs={12}>
                <Paper elevation={3} sx={{ p: 2 }}>
                  <Typography variant="h6" color="secondary" gutterBottom>Bank Details</Typography>
                  <Typography><strong>Account Number:</strong> {selectedShop.shopAccountNumber}</Typography>
                  <Typography><strong>Bank:</strong> {selectedShop.shopBank}</Typography>
                  <Typography><strong>Branch:</strong> {selectedShop.shopBranch}</Typography>
                  <Typography><strong>IFSC:</strong> {selectedShop.shopIFSC}</Typography>
                </Paper>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="h6" color="secondary" gutterBottom>Documents</Typography>
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={() => handleDocumentDialogOpen('shopLicance')}
                  disabled={!selectedShop.shopLicance}
                >
                  View Shop License
                </Button>
                <Button
                  variant="outlined"
                  color="primary"
                  sx={{ ml: 2 }}
                  onClick={() => handleDocumentDialogOpen('shopGstCertificate')}
                  disabled={!selectedShop.shopGstCertificate}
                >
                  View GST Certificate
                </Button>
                <Button
                  variant="outlined"
                  color="primary"
                  sx={{ ml: 2 }}
                  onClick={() => handleDocumentDialogOpen('adhaar')}
                  disabled={!selectedShop.adhaar}
                >
                  View Aadhar
                </Button>
                <Button
                  variant="outlined"
                  color="primary"
                  sx={{ ml: 2 }}
                  onClick={() => handleDocumentDialogOpen('pan')}
                  disabled={!selectedShop.pan}
                >
                  View PAN
                </Button>
                <Button
                  variant="outlined"
                  color="primary"
                  sx={{ ml: 2 }}
                  onClick={() => handleDocumentDialogOpen('bankProof')}
                  disabled={!selectedShop.bankProof}
                >
                  View Bank Proof
                </Button>
                <Button
                  variant="outlined"
                  color="primary"
                  sx={{ ml: 2 }}
                  onClick={() => handleDocumentDialogOpen('shopPhoto')}
                  disabled={!selectedShop.shopPhoto}
                >
                  View Shop Photo
                </Button>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Remark"
                  value={remark}
                  onChange={(e) => setRemark(e.target.value)}
                  fullWidth
                  multiline
                  rows={3}
                  sx={{ mt: 2 }}
                />
              </Grid>
            </Grid>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose} color="secondary">
            Close
          </Button>
          <Button onClick={handleReject} color="error" variant="contained">
            Reject
          </Button>
          <Button onClick={handleActivate} color="success" variant="contained">
            Activate
          </Button>
        </DialogActions>
      </Dialog>

      {/* Dialog for showing document */}
      <Dialog 
        open={documentDialogOpen} 
        onClose={handleDocumentDialogClose} 
        fullWidth 
        maxWidth="md"
      >
        <DialogTitle>
          {selectedDocumentType ? selectedDocumentType.replace(/([A-Z])/g, ' $1').trim() : 'Document'}
        </DialogTitle>
        <DialogContent>
          {renderDocument(selectedDocument)}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDocumentDialogClose} color="secondary">
            Close
          </Button>
        </DialogActions>
      </Dialog>

      {/* Snackbar for success/error messages */}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={() => setSnackbarOpen(false)}
      >
        <Alert
          onClose={() => setSnackbarOpen(false)}
          severity={snackbarSeverity}
          sx={{ width: '100%' }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default VerifyDocuments;