import React, { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import {
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
} from '@mui/material';
import DashboardIcon from '@mui/icons-material/Dashboard';
import PersonIcon from '@mui/icons-material/Person';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import StorefrontIcon from '@mui/icons-material/Storefront';
import MobileFriendlyIcon from '@mui/icons-material/MobileFriendly';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import AddBusinessIcon from '@mui/icons-material/AddBusiness';
import SummarizeIcon from '@mui/icons-material/Summarize';
import AssessmentIcon from '@mui/icons-material/Assessment';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import FilePresentIcon from '@mui/icons-material/FilePresent';
import {jwtDecode} from 'jwt-decode';

// Define the navigation items for level 1 users
const navItems1 = [
  {
    name: 'Dashboard',
    icon: <DashboardIcon />,
    path: '/dashboard',
  },
  {
    name: 'Shop Report',
    icon: <SummarizeIcon />,
    path: '/shopReport',
  },
  {
    name: 'MSB Report',
    icon: <AssessmentIcon />,
    path: '/msbReport',
  },
  {
    name: 'Customer Report',
    icon: <SupportAgentIcon />,
    path: '/customerReport',
  },
  {
    name: 'Admin Report',
    icon: <FilePresentIcon />,
    path: '/adminReport',
  },
   {
    name: 'Bank',
    icon: <AccountBalanceIcon />,
    path: '/bank',
  },
  // {
  //   name: 'Shops',
  //   icon: <StorefrontIcon />,
  //   path: '/shops',
  // },
  // {
  //   name: 'Users',
  //   icon: <MobileFriendlyIcon />,
  //   path: '/users',
  // },
 
  // {
  //   name: 'Accounts',
  //   icon: <AccountBalanceWalletIcon />,
  //   path: '/accounts',
  // },
  {
    name: 'Admin Control',
    icon: <AdminPanelSettingsIcon />,
    path: '/adminControl',
  },
  {
    name: 'Profile',
    icon: <PersonIcon />,
    path: '/profile',
  },
];

// Define the navigation items for level 2 users
const navItems2 = [
  {
    name: 'Dashboard',
    icon: <DashboardIcon />,
    path: '/dashboard',
  },
  {
    name: 'Add Shop',
    icon: <StorefrontIcon />,
    path: '/addShop',
  },
  {
    name: 'Shop Report',
    icon: <SummarizeIcon />,
    path: '/shopReport',
  },
  {
    name: 'MSB Report',
    icon: <AssessmentIcon />,
    path: '/msbReport',
  },
  {
    name: 'Customer Report',
    icon: <SupportAgentIcon />,
    path: '/customerReport',
  },
  {
    name: 'Admin Report',
    icon: <FilePresentIcon />,
    path: '/adminReport',
  },
  {
    name: 'Bank',
    icon: <AccountBalanceIcon />,
    path: '/bank',
  },
  
  
  // {
  //   name: 'Shops',
  //   icon: <StorefrontIcon />,
  //   path: '/shops',
  // },
  // {
  //   name: 'Users',
  //   icon: <MobileFriendlyIcon />,
  //   path: '/users',
  // },
  // {
  //   name: 'Bank',
  //   icon: <AccountBalanceIcon />,
  //   path: '/bank',
  // },
  // {
  //   name: 'Accounts',
  //   icon: <AccountBalanceWalletIcon />,
  //   path: '/accounts',
  // },
  {
    name: 'Admin Control',
    icon: <AdminPanelSettingsIcon />,
    path: '/adminControl',
  },
  {
    name: 'Profile',
    icon: <PersonIcon />,
    path: '/profile',
  },
];
const navItems3 = [
  {
    name: 'Dashboard',
    icon: <DashboardIcon />,
    path: '/dashboard',
  },
  {
    name: 'Shop Report',
    icon: <SummarizeIcon />,
    path: '/shopReport',
  },
  {
    name: 'MSB Report',
    icon: <AssessmentIcon />,
    path: '/msbReport',
  },
  {
    name: 'Customer Report',
    icon: <SupportAgentIcon />,
    path: '/customerReport',
  },
  {
    name: 'Admin Report',
    icon: <FilePresentIcon />,
    path: '/adminReport',
  },
  {
    name: 'Bank',
    icon: <AccountBalanceIcon />,
    path: '/bank',
  },
  {
    name: 'Add Shop',
    icon: <StorefrontIcon />,
    path: '/addShop',
  },
  // {
  //   name: 'Shops',
  //   icon: <StorefrontIcon />,
  //   path: '/shops',
  // },
  // {
  //   name: 'Users',
  //   icon: <MobileFriendlyIcon />,
  //   path: '/users',
  // },
  // {
  //   name: 'Bank',
  //   icon: <AccountBalanceIcon />,
  //   path: '/bank',
  // },
  // {
  //   name: 'Accounts',
  //   icon: <AccountBalanceWalletIcon />,
  //   path: '/accounts',
  // },
  {
    name: 'Admin Control',
    icon: <AdminPanelSettingsIcon />,
    path: '/adminControl',
  },
  {
    name: 'Profile',
    icon: <PersonIcon />,
    path: '/profile',
  },
];
const navItems4 = [
  {
    name: 'Dashboard',
    icon: <DashboardIcon />,
    path: '/dashboard',
  },
  {
    name: 'Add Shop',
    icon: <StorefrontIcon />,
    path: '/addShop',
  },
  {
    name: 'Shop Enquiry',
    icon: <AddBusinessIcon />,
    path: '/shopEnquiry',
  },
  {
    name: 'MSB Report',
    icon: <AssessmentIcon />,
    path: '/msbReport',
  },
  // {
  //   name: 'Users',
  //   icon: <MobileFriendlyIcon />,
  //   path: '/users',
  // },
  // {
  //   name: 'Bank',
  //   icon: <AccountBalanceIcon />,
  //   path: '/bank',
  // },
  // {
  //   name: 'Accounts',
  //   icon: <AccountBalanceWalletIcon />,
  //   path: '/accounts',
  // },
  {
    name: 'Admin Control',
    icon: <AdminPanelSettingsIcon />,
    path: '/adminControl',
  },
  {
    name: 'Profile',
    icon: <PersonIcon />,
    path: '/profile',
  },
];
const navItems5= [
  {
    name: 'Dashboard',
    icon: <DashboardIcon />,
    path: '/dashboard',
  },
  {
    name: 'Shop Enquiry',
    icon: <AddBusinessIcon />,
    path: '/shopEnquiry',
  },
  // {
  //   name: 'Users',
  //   icon: <MobileFriendlyIcon />,
  //   path: '/users',
  // },
  // {
  //   name: 'Bank',
  //   icon: <AccountBalanceIcon />,
  //   path: '/bank',
  // },
  // {
  //   name: 'Accounts',
  //   icon: <AccountBalanceWalletIcon />,
  //   path: '/accounts',
  // },
  // {
  //   name: 'Admin Control',
  //   icon: <AdminPanelSettingsIcon />,
  //   path: '/adminControl',
  // },
  {
    name: 'Profile',
    icon: <PersonIcon />,
    path: '/profile',
  },
];

const Sidebar = () => {
  const [userLevel, setUserLevel] = useState(5); // Default to lowest access level
  const [navItems, setNavItems] = useState([]);

  useEffect(() => {
    const token = localStorage.getItem('token'); // Assuming the token is stored in localStorage
    if (token) {
      try {
        const decodedToken = jwtDecode(token);
        const level = decodedToken.level || 5;
        setUserLevel(level); // Set the user level
        // Set the navigation items based on the user level
        if (level === 1) {
          setNavItems(navItems1);
        } else if (level === 2) {
          setNavItems(navItems2);
        } 
        else if (level === 3) {
          setNavItems(navItems3);
        }
        else if (level === 4) {
          setNavItems(navItems4);
        }
        else if (level === 5) {
          setNavItems(navItems5);
        }
        else {
          setNavItems([]); // Empty array for other levels
        }
      } catch (error) {
        console.error('Error decoding token:', error);
        setUserLevel(5); // Set to lowest access level if there's an error
        setNavItems([]); // Clear navigation if error occurs
      }
    }
  }, []);

  return (
    <List>
      {/* Render navigation items dynamically */}
      {navItems.map((item) => (
        <ListItem
          key={item.name}
          button
          component={NavLink}
          to={item.path}
          sx={{
            '&.active': {
              backgroundColor: 'primary.main',
              color: 'white',
              '& .MuiListItemIcon-root': {
                color: 'white',
              },
            },
            '&:hover': {
              backgroundColor: 'primary.light',
              color: 'white',
              '& .MuiListItemIcon-root': {
                color: 'white',
              },
            },
          }}
        >
          <ListItemIcon>{item.icon}</ListItemIcon>
          <ListItemText primary={item.name} />
        </ListItem>
      ))}
    </List>
  );
};

export default Sidebar;
