import React from 'react';
import { Box, Typography, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

const NotFound = () => {
  const navigate = useNavigate();

  const handleGoHome = () => {
    navigate('/');
  };


  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '70vh',
        textAlign: 'center',
        p: 3,
        bgcolor: 'background.paper',
      }}
    >
   
                <img
            src="/images/not-found.svg"  // Corrected src attribute
            alt="404 Not Found"
            style={{ maxWidth: '70%', height: 'auto', marginBottom: '10px' }}
            />

      <Typography variant="h4" sx={{ color: 'text.primary', mb: 2 }}>
        Oops! Page not found.
      </Typography>
      <Typography variant="body1" sx={{ color: 'text.secondary', mb: 4 }}>
        The page you're looking for doesn't exist or has been moved.
      </Typography>
      <Button
        variant="contained"
        color="primary"
        onClick={handleGoHome}
        sx={{ textTransform: 'none', p: 1.5, px: 4 }}
      >
        Go to Home
      </Button>
    </Box>
  );
};

export default NotFound;
